import React from "react";
import { SafeAreaView, View } from "react-native";
import Toast from "react-native-toast-message";
import { Provider } from "react-redux";
import { NativeBaseProvider } from "native-base";
import { GoText } from "./components";
import Routes from "./routes";
import { store } from "./store";
import { Theme, NativeBaseTheme } from "./styles";
import { registerRootComponent } from "expo";

function App() {
  const toastConfig = {
    success: (props: { text1: string; text2: string }) => (
      <View
        style={{
          height: 60,
          width: "90%",
          backgroundColor: Theme.colors.title,
          marginHorizontal: Theme.sizes.marginL,
          borderLeftColor: Theme.colors.success,
          borderLeftWidth: 5,
          padding: Theme.sizes.paddingM,
          paddingLeft: Theme.sizes.paddingL,
          borderColor: Theme.colors.border,
          borderWidth: 1,
          borderRadius: Theme.sizes.borderRadiusS,
          justifyContent: "space-around",
        }}
      >
        <GoText h3 blackColor>
          {props.text1}
        </GoText>
        <GoText body4 blackColor>
          {props.text2}
        </GoText>
      </View>
    ),

    error: (props: { text1: string; text2: string }) => (
      <View
        style={{
          height: 70,
          width: "90%",
          backgroundColor: Theme.colors.title,
          marginHorizontal: Theme.sizes.marginL,
          borderLeftColor: Theme.colors.error,
          borderLeftWidth: 5,
          padding: Theme.sizes.paddingM,
          paddingLeft: Theme.sizes.paddingL,
          borderColor: Theme.colors.border,
          borderWidth: 1,
          borderRadius: Theme.sizes.borderRadiusS,
          justifyContent: "space-around",
        }}
      >
        <GoText h3 blackColor>
          {props.text1}
        </GoText>
        <GoText body4 blackColor>
          {props.text2}
        </GoText>
      </View>
    ),
  };

  if ("caches" in window) {
    caches.keys().then(function (names) {
      names.forEach(function (name) {
        caches.delete(name);
      });
    });
  }

  return (
    <>
      <Provider store={store}>
        <NativeBaseProvider theme={NativeBaseTheme}>
          <SafeAreaView style={{ flex: 1 }}>
            <Routes />
          </SafeAreaView>
        </NativeBaseProvider>
      </Provider>
      <Toast config={toastConfig} />
    </>
  );
}

registerRootComponent(App);

export default App;
