import React from "react";

import {
  NavigationContainer,
  createNavigationContainerRef,
  DrawerNavigationState,
  ParamListBase,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import {
  createDrawerNavigator,
  DrawerItemList,
} from "@react-navigation/drawer";

import { Auth } from "aws-amplify";
import awsconfig from "./aws-exports";
import { RootStackParamList } from "./types";
import { userLogin, removeUserAuthData } from "./store/authSlice";
import { removeTenantData } from "./store/tenantSlice";

import AuthFlow from "./pages/Auth";
import NotFound from "./pages/404";
import ForgotPassword from "./pages/ForgotPassword";
import ChangePassword from "./pages/ChangePassword";
import i18nDictionary from "./i18n";
import {
  SafeAreaView,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import SignOut from "./assets/svg/signout";
import { Theme } from "./styles";
import { useAppDispatch } from "./store/hooks";
import {
  DrawerNavigationHelpers,
  DrawerDescriptorMap,
} from "@react-navigation/drawer/lib/typescript/src/types";
import Home from "./pages/Home";
import VersionSideMenu from "../shared/molecules/VersionSideMenu";
import HelpSideMenu from "../shared/molecules/HelpSideMenu";

const Stack = createNativeStackNavigator<RootStackParamList>();
const navigationRef = createNavigationContainerRef();

Auth.configure(awsconfig);

export default function App(): JSX.Element {
  const dispatch = useAppDispatch();

  const DrawerWithLogoutButton = (
    props: JSX.IntrinsicAttributes & {
      state: DrawerNavigationState<ParamListBase>;
      navigation: DrawerNavigationHelpers;
      descriptors: DrawerDescriptorMap;
    }
  ) => (
    <ScrollView
      contentContainerStyle={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <SafeAreaView>
        <DrawerItemList {...props} />
      </SafeAreaView>

      <View style={styles.bottomDrawer}>
        <HelpSideMenu
          color={Theme.colors.primary}
          helpText={i18nDictionary.screens.Home.help()}
          url={i18nDictionary.screens.Home.helpPage()}
        />
        <VersionSideMenu
          color={Theme.colors.primary}
          versionText={i18nDictionary.screens.Home.version()}
        />
        <TouchableOpacity
          onPress={async () => {
            try {
              const userAuth = await Auth.currentAuthenticatedUser();
              userAuth.signOut();
              props.navigation.closeDrawer();
              dispatch(userLogin({ type: "reject" }));
              dispatch(removeUserAuthData());
              dispatch(removeTenantData());
              props.navigation.navigate("Auth");
            } catch (e) {
              props.navigation.closeDrawer();
              dispatch(userLogin({ type: "reject" }));
              props.navigation.navigate("Auth");
            }
          }}
        >
          <View style={styles.item}>
            <View style={styles.iconContainer}>
              <SignOut width={25} height={25} color={Theme.colors.primary} />
            </View>
            <Text style={styles.label}>{i18nDictionary.cognito.SignOut()}</Text>
          </View>
        </TouchableOpacity>
      </View>
    </ScrollView>
  );

  function Main() {
    const Drawer = createDrawerNavigator();
    return (
      <Drawer.Navigator
        initialRouteName="Home"
        drawerContent={(props) => <DrawerWithLogoutButton {...props} />}
      >
        <Drawer.Screen
          name="Home"
          component={Home}
          options={{
            headerShown: false,
            title: i18nDictionary.screens.Measures.newMeasurement(),
          }}
        />
        <Drawer.Screen
          name="ChangePassword"
          component={ChangePassword}
          options={{
            headerShown: false,
            title: i18nDictionary.cognito.ChangePassword(),
          }}
        />
      </Drawer.Navigator>
    );
  }

  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator initialRouteName="Auth">
        <Stack.Group screenOptions={{ headerShown: false }}>
          <Stack.Screen name="Main" component={Main} />

          <Stack.Screen name="Auth" component={AuthFlow} />

          <Stack.Screen name="ForgotPassword" component={ForgotPassword} />

          <Stack.Screen name="NotFound" component={NotFound} />
        </Stack.Group>
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    alignItems: "center",
  },
  label: {
    marginVertical: 16,
    margin: 4,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, .87)",
  },
  bottomDrawer: {
    flexDirection: "column",
  },
  iconContainer: {
    marginLeft: 16,
    width: 24,
    alignItems: "center",
  },
  icon: {
    width: 24,
    height: 24,
  },
});
