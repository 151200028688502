import React from "react";
import { Heading, VStack, Text, Row } from "native-base";
import i18nDictionary from "../../i18n";
import { dateToHoursMinutes } from "../../helpers/dateConversion";
import { useAppSelector } from "../../store/hooks";

interface ResultHeaderProps {
  leftHeading: string;
  rightHeading: string;
}

export const ResultHeader: React.FC<ResultHeaderProps> = ({
  leftHeading,
  rightHeading,
}) => (
  <Row justifyContent="space-between">
    <Heading size="md">{leftHeading}</Heading>
    <Heading size="md">{rightHeading}</Heading>
  </Row>
);

export type Dimensions = {
  length: number;
  width: number;
  height: number;
};

export type MeasurementResultProps = {
  createdAt: Date;
  pointcloudUrl?: string;
} & (
  | {
      volume: number;
    }
  | {
      dimensions: Dimensions;
    }
);

export function MeasurementResult({
  measurement,
}: {
  measurement: MeasurementResultProps;
}) {
  const locales = useAppSelector(
    ({ tenant }) => tenant.sessionData?.tenant.config.locales
  );
  const dateTimeOptions = useAppSelector(
    ({ tenant }) => tenant.sessionData?.tenant.config.dateTimeOptions
  );

  const volumeHeading = `${i18nDictionary.screens.Measures.volume()} (m\u00b3)`;
  const dimensionsHeading = `${i18nDictionary.screens.Measures.dimensions()} (LxWxH m)`;

  const leftHeading =
    "volume" in measurement ? volumeHeading : dimensionsHeading;
  const resultValue =
    "volume" in measurement
      ? measurement.volume.toFixed(2)
      : dimensionsToString(measurement.dimensions);

  return (
    <VStack space="3">
      <ResultHeader
        leftHeading={leftHeading}
        rightHeading={i18nDictionary.screens.Measures.time()}
      />
      <Row justifyContent="space-between">
        <Text fontSize="lg">{resultValue}</Text>
        <Text fontSize="lg">
          {dateToHoursMinutes(measurement.createdAt, locales, dateTimeOptions)}h
        </Text>
      </Row>
    </VStack>
  );
}

function cmToM(cm: number): number {
  return cm / 100;
}

function dimensionsToString(dimensions: Dimensions): string {
  return `${cmToM(dimensions.length).toFixed(2)} x ${cmToM(
    dimensions.width
  ).toFixed(2)} x ${cmToM(dimensions.height).toFixed(2)}`;
}
