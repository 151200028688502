import React from 'react';
import { View } from 'react-native'
import { Theme } from '../../styles';
import GoText from '../GoText';

// Components

// Internalization
// Libs
// Utils


const FloatingLabel = (props: {
    label: string,
}) => {
    return (
        <View style={{ backgroundColor: Theme.colors.background, position: 'absolute', marginTop: -10, marginLeft: 5, paddingHorizontal: 5, zIndex: 1, elevation: 1, borderRadius: 25 }}>
            <GoText size={12} blackColor>{props.label}</GoText>
        </View>
    )
}



export default FloatingLabel;