import React from 'react'
import Svg, { Path } from 'react-native-svg';
const EyeSlash = (props: {width: number, height: number, color: string}) => {
    return (
        <Svg width={props.width} height={props.height} viewBox="0 0 22 19" fill="none" >
            <Path d="M10.9063 12.6249C11.6025 12.6249 12.2701 12.3484 12.7624 11.8561C13.2547 11.3638 13.5313 10.6961 13.5313 9.99995C13.5313 9.92307 13.5277 9.8469 13.5212 9.77167L10.678 12.6149C10.7532 12.6214 10.8292 12.6249 10.9063 12.6249ZM19.595 1.88026L18.5938 0.879947C18.5586 0.844811 18.5109 0.825073 18.4612 0.825073C18.4115 0.825073 18.3638 0.844811 18.3287 0.879947L15.7665 3.44284C14.3531 2.72049 12.7642 2.35932 11 2.35932C6.49533 2.35932 3.13439 4.70542 0.917201 9.3976C0.828081 9.58631 0.78186 9.79242 0.78186 10.0011C0.78186 10.2098 0.828081 10.4159 0.917201 10.6046C1.80314 12.4707 2.86994 13.9664 4.11759 15.0917L1.63814 17.5703C1.603 17.6054 1.58326 17.6531 1.58326 17.7028C1.58326 17.7525 1.603 17.8002 1.63814 17.8353L2.63869 18.8359C2.67384 18.871 2.72152 18.8908 2.77122 18.8908C2.82093 18.8908 2.8686 18.871 2.90376 18.8359L19.595 2.14557C19.6124 2.12816 19.6263 2.10748 19.6357 2.08472C19.6451 2.06196 19.65 2.03756 19.65 2.01292C19.65 1.98828 19.6451 1.96388 19.6357 1.94111C19.6263 1.91835 19.6124 1.89767 19.595 1.88026ZM6.78126 9.99995C6.7812 9.28746 6.96568 8.5871 7.31673 7.96711C7.66779 7.34711 8.17345 6.82861 8.78445 6.46212C9.39545 6.09564 10.091 5.89366 10.8032 5.87586C11.5155 5.85806 12.2202 6.02506 12.8488 6.36057L11.7092 7.5001C11.2497 7.35295 10.7585 7.33523 10.2895 7.44888C9.82051 7.56253 9.39191 7.80317 9.05069 8.14438C8.70948 8.48559 8.46885 8.91419 8.3552 9.38317C8.24155 9.85214 8.25927 10.3434 8.40642 10.8029L7.26689 11.9424C6.94711 11.345 6.78027 10.6776 6.78126 9.99995V9.99995Z" fill={props.color} />
            <Path d="M21.0828 9.39529C20.2578 7.65779 19.2759 6.24099 18.1369 5.1449L14.7587 8.52341C15.0434 9.26782 15.1066 10.0788 14.9404 10.8583C14.7742 11.6378 14.3859 12.3525 13.8223 12.9161C13.2588 13.4797 12.544 13.868 11.7645 14.0341C10.985 14.2003 10.1741 14.1372 9.42968 13.8524L6.56421 16.7179C7.89358 17.333 9.37218 17.6406 11 17.6406C15.5047 17.6406 18.8656 15.2945 21.0828 10.6023C21.1719 10.4136 21.2182 10.2075 21.2182 9.9988C21.2182 9.7901 21.1719 9.584 21.0828 9.39529V9.39529Z" fill={props.color} />
        </Svg>
    );
}

export default EyeSlash;