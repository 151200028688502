import React from 'react';
import { Text, View, StyleSheet } from 'react-native';

export type FloatingLabelProps = {
  text: string,
  color: string,
  backgroundColor: string
};

export function FloatingLabel({ text, color, backgroundColor }: FloatingLabelProps) {
  return (
    <View style={{...styles.text, backgroundColor: backgroundColor}}>
      <Text style={{fontSize: 12, color: color}}>{text}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  text: { position: 'absolute', marginTop: -10, marginLeft: 5, paddingHorizontal: 5, zIndex: 1, elevation: 1, borderRadius: 30 },
});