import React from "react";

import { HeaderBar } from "../../components";
import { RootStackScreenProps } from "../../types";

import { DrawerActions } from "@react-navigation/native";
import Measure from "../Measure";

export default function Home({ navigation }: RootStackScreenProps<"Main">) {
  return (
    <>
      <HeaderBar
        hasLogo={"true"}
        goBack={false}
        nextStep={() => navigation.dispatch(DrawerActions.openDrawer())}
      />
      <Measure />
    </>
  );
}
