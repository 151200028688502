import { IconButton } from "react-native-paper";
import styled from "styled-components/native";
import { Theme } from "../../styles";

export const ButtonContainer = styled(IconButton)`
  margin: 0px 0px 0px 12px;
  min-width: 60px;
  max-width: 60px;
  max-height: 60px;
  min-height: 60px;
  border-radius: 6px;
  background-color: ${Theme.colors.error};
`;
