import React from "react";
import { Theme } from "../../styles";

import { ButtonContainer } from "./styles";

interface Props {
  onPress: () => void;
}

export function SquareButton({ onPress: handleOnPress }: Props): JSX.Element {
  return (
    <ButtonContainer
      icon="delete"
      size={24}
      iconColor={Theme.colors.backgroundLight}
      onPress={handleOnPress}
    />
  );
}
