import { Dictionary } from "../index";

const es: Dictionary = {
  screens: {
    NotFound: {
      message: "Página no encontrada",
    },
    Measures: {
      scale: "Escala",
      category: "Categoría",
      container: "Contentor",
      device: "Dispositivo",
      measurementType: "Tipo de Medida",
      tare: "Tara",
      submit: "Enviar",
      dashboard: "Panel de Control",
      newMeasurement: "Nueva medida",
      volume: "Volumen",
      dimensions: "Dimensiones",
      time: "Horario",
      download3DView: "Descargar datos 3D",
      errorMessageSubmit:
        "Error al enviar los datos de medición solicitados, inténtelo de nuevo.",
      errorMessageUnknownDevice: "Dispositivo desconocido",
      errorMessageUnknownContainer: "Contenedor desconocido",
      errorMessageUnboundTimeout: "Tiempo de espera caducado",
      errorMessageUnauthorizedRequest: "No autorizado",
      errorMessageDeviceNotReady:
        "El servidor no está listo para iniciar una nueva medición de volumen",
      errorMessageVolumeComputation: "Error de cálculo de volumen",
      errorMessageUnexpected: "Un problema en el servidor.",
      errorMessageUnknownUsecase: "Caso de uso desconocido",
      errorMessageUnboundDelay: "Retraso en la respuesta del servidor",
      errorMessageInvalidContentType: "Formato de contenido no válido",
    },
    Home: {
      emptyServiceList:
        "Haga clic en el botón '+' para programar un nuevo servicio.",
      emptyCompletedServices: "No hay servicios completados.",
      scheduled: "Programado",
      completed: "Terminado",
      version: "Versión",
      helpPage: "https://goclever.io/senseapp-es/",
      help: "Ayuda",
    },
    CreateService: {
      header: "Registro de Descarte de Residuos",
      orderNumber: "Número de Pedido",
      register: "Registrar",
      cancel: "Cancelar",
    },
    ServiceCategories: {
      category: "Categoría",
      measured: "Medido",
      completeService: "Finalizar servicio",
      emptyCategoriesList: "Ninguna categoria disponíble.",
    },
    ServiceMeasurements: {
      declared: "Declarado",
      measured: "Medido",
      measurement: "Medición",
      volume: "Volumen",
      newMeasurement: "Nueva medición",
      emptyMeasurementsList: "Ninguna medición hecha",
      container: "Contenedor",
      device: "Dispositivo",
    },
  },
  cognito: {
    SignIn: "Iniciar sesión",
    SignOut: "Desconectar",
    Username: "Email",
    Password: "Contraseña",
    Email: "Email",
    Confirm: "Confirmar",
    Cancel: "Cancelar",
    Submit: "Enviar",
    Skip: "Omitir",
    Verify: "Verificar",
    Code: "Código",
    Anonymous: "Acceso Anónimo",
    IncorrectLogin: "Nombre de usuario o contraseña incorrecto",
    ChangePassword: "Cambio de contraseña",
    OldPassword: "Contraseña anterior",
    NewPassword: "Nueva contraseña",
    ConfirmPassword: "Repite la contraseña",
    ForgotPassword: "Olvidé la contraseña",
    EmailConfirmCode: "Cambio de contraseña",
    ConfirmationCode: "Código de confirmación",
    FirstAccess: "Primer Acceso",
    SuccessMessageChangePassword: "Contraseña alterada con éxito",
    SuccessMessageSubmit: "¡Medida enviada con éxito!",
    SuccessMessageTara: "¡Tara enviada con éxito!",
    ErrorMessageDifferentPasswords:
      "La confirmación de la contraseña no coincide",
    ErrorEmptyFieldsMessage:
      "Campos incompletos, complete todos los campos y vuelva a intentarlo",
    ErrorGeneralMessage: "Hubo un error, por favor intente de nuevo",
    NotAuthorizedException: "Contraseña incorrecta, intente de nuevo",
    UserNotFoundException:
      "No se encontró la combinación de nombre de usuario/ID de cliente.",
    CodeMismatchException:
      "Se proporcionó un código de verificación no válido, inténtelo de nuevo.",
    InvalidPasswordException:
      "La contraseña no cumple con la política: la contraseña no es lo suficientemente larga",
    InvalidParameterException:
      "Campo de nombre de usuario y/o contraseña vacío",
    PasswordResetRequiredException:
      "Restablecimiento de contraseña requerido para el usuario",
  },
  popups: {
    errors: {
      computationOngoing: "Cálculo en curso",
      computationTimeout: "Se continuará en segundo plano!",
      downloadErrorTitle: "Error de descarga",
      downloadErrorText: "Por favor, intente de nuevo más tarde.",
      fetchErrorTitle: "Error",
      fetchErrorText: "Lista de servicios no disponible.",
      registrationErrorTitle: "Error de registro",
      registrationErrorText: "Servicio no pudo ser registrado.",
      updateErrorTitle: "Error de actualización",
      updateErrorText: "Servicio no pudo ser finalizado.",
      measurementErrorTitle: "Error de medición",
      measurementDeleteErrorTitle: "Error",
      measurementDeleteErrorText: "No se pudo eliminar la medición.",
      measurementPatchErrorTitle: "Erro",
      measurementPatchErrorText:
        "La medición no pudo ser actualizada. Por favor, intente de nuevo.",
      orderNumberCanNotBeEmpty: "Por favor, insira un número de pedido",
      orderNumberTooLong: "Número de pedido demasiado largo",
      volumeCanNotBeEmpty: "El volumen no puede estar vacío",
      volumeHasInvalidChars: "El volumen contiene caracteres inválidos.",
      volumeInWrongFormat: "El volumen debe ser un número con formato 0.00",
      volumeInvalid: "Volumen inválido",
      volumeOutOfBounds: "El volumen debe estar entre 0 y 100",
    },
    success: {
      volume: "Volumen",
      deletedVolumeTitle: "Volumen eliminado",
    },
  },
};

export default es;
