import React, { useState } from "react";
import {
  ActivityIndicator,
  Keyboard,
  StyleSheet,
  KeyboardAvoidingView,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import { HeaderBar } from "../components";
import { Theme } from "../styles";
import { Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import i18nDictionary from "../i18n";
// import * as Sentry from "sentry-expo";
import Toast from "react-native-toast-message";
import { DrawerActions } from "@react-navigation/native";
import Pif from "../../shared/svg/pif";
import { ScrollView } from "react-native-gesture-handler";
import Text from "../../shared/atoms/Text";
import { Sizes } from "../../shared/constants/sizes";
import Input from "../../shared/molecules/Input";
import { RootDrawerScreenProps, RootStackScreenProps } from "../types";

Auth.configure(awsconfig);

const ChangePassword = ({
  route,
  navigation,
}: RootDrawerScreenProps<"ChangePassword">) => {
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [loadingButton, setLoadingButton] = useState(false);

  const resetFields = () => {
    setNewPassword("");
    setOldPassword("");
    setNewPasswordConfirm("");
    setLoadingButton(false);
  };

  const sendPwdChange = async () => {
    setLoadingButton(true);
    if (newPasswordConfirm !== newPassword) {
      setLoadingButton(false);
      Toast.show({
        type: "error",
        text1: "Ops..",
        text2: i18nDictionary.cognito.ErrorMessageDifferentPasswords(),
      });
    } else if (
      newPasswordConfirm === "" ||
      newPassword === "" ||
      oldPassword === ""
    ) {
      setLoadingButton(false);
      Toast.show({
        type: "error",
        text1: "Ops..",
        text2: i18nDictionary.cognito.ErrorMessageDifferentPasswords(),
      });
    } else {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then((data) => {
          navigation.navigate("Home");
          Toast.show({
            type: "success",
            text1: "Hey",
            text2: i18nDictionary.cognito.SuccessMessageChangePassword(),
          });
          resetFields();
          setLoadingButton(false);
        })
        .catch((err) => {
          // Platform.OS === "web"
          //   ? Sentry.Browser.captureException(err)
          //   : Sentry.Native.captureException(err);
          resetFields();
          Toast.show({
            type: "error",
            text1:
              //@ts-ignore
              i18nDictionary.cognito[err.name + "Title"] !== undefined
                ? //@ts-ignore
                  i18nDictionary.cognito[err.name + "Title"]()
                : "Error",
            text2:
              //@ts-ignore
              i18nDictionary.cognito[err.name] !== undefined
                ? //@ts-ignore
                  i18nDictionary.cognito[err.name]()
                : i18nDictionary.cognito.ErrorGeneralMessage(),
          });
          setLoadingButton(false);
        });
    }
  };

  const cancelFlow = () => {
    resetFields();
    navigation.navigate("Home");
  };

  return (
    <>
      <HeaderBar
        hasLogo={"false"}
        goBack={false}
        nextStep={() => navigation.dispatch(DrawerActions.openDrawer())}
      />
      <View style={styles.safeArea}>
        <ScrollView contentContainerStyle={styles.container}>
          <Text
            style={{
              fontSize: 48,
              color: Theme.colors.primary,
              fontWeight: "700",
              marginVertical: 20,
            }}
          >
            goclever
          </Text>

          <Text style={{ fontSize: Sizes.body1, color: Theme.colors.primary }}>
            {i18nDictionary.cognito.ChangePassword()}
          </Text>

          <View style={{ width: "100%" }}>
            <Input
              onChange={setOldPassword}
              value={oldPassword}
              label={i18nDictionary.cognito.OldPassword()}
              secureTextEntry={true}
              error={false}
              editable={true}
            />
            <Input
              onChange={setNewPassword}
              value={newPassword}
              label={i18nDictionary.cognito.NewPassword()}
              secureTextEntry={true}
              error={false}
              editable={true}
            />
            <Input
              onChange={setNewPasswordConfirm}
              value={newPasswordConfirm}
              label={i18nDictionary.cognito.ConfirmPassword()}
              secureTextEntry={true}
              error={false}
              editable={true}
              onSubmitEditing={() => {
                Keyboard.dismiss();
                sendPwdChange();
              }}
            />
          </View>

          <View
            style={{
              marginVertical: Theme.sizes.marginL,
              flexDirection: "row",
              width: "75%",
              justifyContent: "space-around",
            }}
          >
            <TouchableOpacity
              onPress={() => sendPwdChange()}
              style={styles.confirmButton}
            >
              {loadingButton ? (
                <ActivityIndicator color={Theme.colors.title} size={25} />
              ) : (
                <Text
                  style={{ fontSize: Sizes.body3, color: Theme.colors.title }}
                >
                  {i18nDictionary.cognito.Submit()}
                </Text>
              )}
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => cancelFlow()}
              style={styles.cancelButton}
            >
              <Text
                style={{ fontSize: Sizes.body3, color: Theme.colors.title }}
              >
                {i18nDictionary.cognito.Cancel()}
              </Text>
            </TouchableOpacity>
          </View>
        </ScrollView>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    height: "100%",
    alignItems: "center",
    backgroundColor: Theme.colors.title,
    flex: 1,
  },
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: Theme.sizes.paddingM,
  },
  confirmButton: {
    width: "45%",
    borderRadius: Theme.sizes.borderRadiusM,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.colors.success,
    height: Theme.sizes.heightS,
    padding: Theme.sizes.paddingS,
    borderColor: "#fff",
    borderWidth: 1,
  },
  cancelButton: {
    width: "45%",
    borderRadius: Theme.sizes.borderRadiusM,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.colors.error,
    height: Theme.sizes.heightS,
    padding: Theme.sizes.paddingS,
  },
});

export default ChangePassword;
