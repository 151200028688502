import React, { useState } from 'react'
import { View, TextInput, StyleSheet, KeyboardTypeOptions, TouchableOpacity } from 'react-native'

// Components
import FloatingLabel from "./FloatingLabel"

// Constants
import { Theme } from '../styles'
import Eye from '../assets/svg/eye'
import EyeSlash from '../assets/svg/eye_slash'
import Email from '../assets/svg/email'
import Password from '../assets/svg/password'

interface InputProps {
    onChange: (value: string) => void
    value?: string,
    label: string,
    secureTextEntry?: boolean,
    keyboardType?: KeyboardTypeOptions,
    selectTextOnFocus?: boolean,
    onSubmitEditing?: () => void,
    icon?: string,
    error: boolean
}

const Input = ({ onChange, value, label, secureTextEntry, keyboardType, selectTextOnFocus, onSubmitEditing, icon, error }: InputProps) => {
    const [security, setSecurity] = useState(secureTextEntry)

    const getIconLogin = (icon: string) => {
        switch (icon) {
            case "email":
                return <Email width={20} height={20} color={error ? Theme.colors.error : Theme.colors.border} />

            case "password":
                return <Password width={20} height={20} color={error ? Theme.colors.error : Theme.colors.border} />

            default:
                break;
        }
    }

    return (
        <View>
            <View style={{ ...styles.container, borderColor: error ? Theme.colors.error : Theme.colors.border }}>
            <FloatingLabel label={label} />

                <View style={{ flexDirection: 'row' }}>
                    {icon !== undefined && (
                        <View style={styles.icon}>
                            {getIconLogin(icon)}
                        </View>
                    )}

                    <TextInput
                        value={value}
                        style={{ ...styles.input, paddingLeft: icon !== undefined ? 40 : 10 }}
                        onChangeText={onChange}
                        blurOnSubmit={false}
                        multiline={false}
                        selectTextOnFocus={selectTextOnFocus}
                        keyboardType={keyboardType}
                        onSubmitEditing={onSubmitEditing}
                        secureTextEntry={security}
                    />

                    {secureTextEntry && (
                        <TouchableOpacity style={styles.touch} onPress={() => setSecurity(!security)}>
                            {security ? (
                                <Eye width={20} height={20} color={Theme.colors.border} />
                            ) : (
                                <EyeSlash width={20} height={20} color={Theme.colors.border} />
                            )}
                        </TouchableOpacity>
                    )}
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        marginTop: Theme.sizes.marginL,
        borderRadius: Theme.sizes.borderRadiusS
    },
    input: {
        height: 45,
        width: "100%",
        backgroundColor: "#fff",
        paddingTop: 0,
        paddingBottom: 0,
        color: Theme.colors.text_dark,
        borderRadius: Theme.sizes.borderRadiusS,
        fontSize: Theme.sizes.fontSizeM
    },
    touch: {
        position: 'absolute',
        right: 15,
        top: 15
    },
    icon: {
        position: "absolute",
        top: 13,
        left: 10
    }
})

export default Input