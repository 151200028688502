import React from "react";
import {
  Keyboard,
  View,
  TouchableOpacity,
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  StyleSheet,
  Dimensions,
} from "react-native";

// Librarys

// Components

// Internalization
import i18nDictionary from "../../i18n";
import { Theme } from "../../styles";
import Text from "../../../shared/atoms/Text";
import { Sizes } from "../../../shared/constants/sizes";
import Input from "../../../shared/molecules/Input";

interface LoginProps {
  loadingButton: boolean;
  signIn: (email: string, password: string) => void;
  forgotPwd: () => void;
  errorMsg: string;
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (pwd: string) => void;
}

const { width, height } = Dimensions.get("window");

const Login = ({
  loadingButton,
  signIn,
  forgotPwd,
  errorMsg,
  email,
  setEmail,
  password,
  setPassword,
}: LoginProps) => {
  const keyboardVerticalOffset = Platform.OS === "ios" ? 40 : 0;

  const handleTextEmail = (text: string) => {
    setEmail(text.toLowerCase());
  };

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior="position"
      keyboardVerticalOffset={keyboardVerticalOffset}
    >
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          paddingRight: Sizes.paddingL,
          borderRightWidth: width >= 1150 ? 1 : 0,
          borderColor: Theme.colors.title,
          width: "48%",
        }}
      >
        <Text
          style={{
            fontSize: 72,
            color: "#fff",
            fontWeight: "700",
            marginVertical: 20,
          }}
        >
          goclever
        </Text>
        <Text
          style={{
            fontSize: 40,
            color: "#fff",
            fontWeight: "100",
            marginBottom: 60,
          }}
        >
          SENSE
        </Text>
      </View>
      {/* Change to goclever svgs */}

      {/* // */}

      <View
        style={{
          width: Platform.OS === "web" && width >= 1150 ? "48%" : "100%",
          alignItems: "center",
          padding: Platform.OS === "web" && width >= 1150 ? 100 : 0,
        }}
      >
        <View style={{ width: "100%" }}>
          <Input
            value={email}
            onChange={(text) => handleTextEmail(text)}
            label={i18nDictionary.cognito.Username()}
            keyboardType="email-address"
            error={errorMsg !== ""}
          />

          <Input
            value={password}
            onChange={(text) => setPassword(text)}
            label={i18nDictionary.cognito.Password()}
            error={errorMsg !== ""}
            secureTextEntry={true}
            onSubmitEditing={() => {
              Keyboard.dismiss;
              signIn(email, password);
            }}
          />

          {errorMsg !== "" && (
            <Text
              style={{
                fontSize: Sizes.body5,
                color: Theme.colors.error_light,
                marginBottom: Sizes.marginM,
              }}
            >
              {errorMsg}
            </Text>
          )}

          <TouchableOpacity
            style={{ flexDirection: "row" }}
            onPress={() => forgotPwd()}
          >
            <Text
              style={{
                fontSize: Sizes.body3,
                color: Theme.colors.title,
                textDecorationLine: "underline",
                marginTop: Sizes.marginXS,
              }}
            >
              {i18nDictionary.cognito.ForgotPassword()}
            </Text>
          </TouchableOpacity>
        </View>

        <View style={{ marginVertical: 10, width: "50%" }}>
          <TouchableOpacity
            onPress={() => signIn(email, password)}
            style={styles.loginButton}
          >
            {loadingButton ? (
              <ActivityIndicator color={Theme.colors.primary} size={25} />
            ) : (
              <Text
                style={{
                  fontSize: Sizes.h4,
                  fontWeight: "700",
                  color: Theme.colors.primary,
                }}
              >
                {i18nDictionary.cognito.SignIn()}
              </Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: Platform.OS === "web" && width >= 1150 ? "row" : "column",
    justifyContent: "center",
    alignItems: "center",
    padding: Theme.sizes.paddingM,
    paddingTop: 100,
  },
  input: {
    borderColor: "gray",
    backgroundColor: "#FFF",
    width: "100%",
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  loginButton: {
    borderRadius: Theme.sizes.borderRadiusM,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.colors.title,
    height: Theme.sizes.heightS,
    padding: Theme.sizes.paddingS,
    margin: 10,
  },
  logo: {
    width: "48%",
    borderRightWidth: Platform.OS === "web" && width >= 1150 ? 2 : 0,
    justifyContent: "center",
    alignItems: "center",
    marginRight: Platform.OS === "web" && width >= 1150 ? 50 : 0,
    borderColor: Theme.colors.title,
  },
});

export default Login;
