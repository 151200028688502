import React from "react";
import { Ionicons } from "@expo/vector-icons";

import { Button, VStack, Text, Icon } from "native-base";
import Toast from "react-native-toast-message";

import i18nDictionary from "../../i18n";
import { api } from "../../services/api";

import { Dropdown } from "../../components";
import {
  Dimensions,
  MeasurementResult,
  MeasurementResultProps,
} from "./MeasureResults";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  selectContainers,
  selectDefaultMeasurementType,
  selectDevices,
  setTenantData,
} from "../../store/tenantSlice";

import { MeasurementType } from "../../types";
import { getErrorMsg, TimeoutError } from "./errorHandling";

interface BaseMeasurementResultDTO {
  createdAt: string;
  measurementId: number;
}

interface VolumeResultDTO extends BaseMeasurementResultDTO {
  volume: number;
  containerId?: string;
}

interface DimensionsResultDTO extends BaseMeasurementResultDTO {
  dimensions: Dimensions[];
}

export default function Measure() {
  const dispatch = useAppDispatch();

  const deviceList = useAppSelector(selectDevices);
  const containerList = useAppSelector(selectContainers);
  const defaultMeasurementType = useAppSelector(selectDefaultMeasurementType);
  const measurementTypeList = [
    {
      label: i18nDictionary.screens.Measures.volume(),
      value: MeasurementType.Volume,
    },
    {
      label: i18nDictionary.screens.Measures.dimensions(),
      value: MeasurementType.Dimensions,
    },
  ];

  const [measurement, setMeasurement] =
    React.useState<MeasurementResultProps>();
  const [deviceId, setDeviceId] = React.useState(deviceList[0]?.value);
  const [measurementType, setMeasurementType] = React.useState(
    defaultMeasurementType
  );
  const [container, setContainer] = React.useState(containerList[0]?.value);
  const [downloadLoading, setDownloadLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const displayContainerDropdown =
    containerList.length > 0 && measurementType === MeasurementType.Volume;
  const pointcloudUrl = measurement?.pointcloudUrl ?? "";

  // Generic handler for dropdowns
  const dropdownChangeHandler =
    <T,>(setState: React.Dispatch<React.SetStateAction<T>>) =>
    (value: string) => {
      setState(value as T);
      setMeasurement(undefined);
    };

  const handleDownload3dView = async () => {
    try {
      setDownloadLoading(true);
      // const response = await fetch(pointcloudUrl, { method: "HEAD" });
      await handleDownload(pointcloudUrl, 4, 1000);
    } catch (error) {
      Toast.show({
        type: "error",
        text1: i18nDictionary.popups.errors.downloadErrorTitle(),
        text2: i18nDictionary.popups.errors.downloadErrorText(),
      });
    } finally {
      setDownloadLoading(false);
    }
  };

  const handleNewMeasurement = async () => {
    try {
      setLoading(true);

      const result = await postMeasurement();
      const newMeasurement =
        MeasurementType.Volume in result
          ? {
              createdAt: new Date(result.createdAt),
              pointcloudUrl: result.pointcloudUrl,
              volume: result.volume,
            }
          : {
              createdAt: new Date(result.createdAt),
              pointcloudUrl: result.pointcloudUrl,
              dimensions: result.dimensions[0],
            };

      setMeasurement(newMeasurement);
      setLoading(false);
    } catch (error) {
      const errorMsg = getErrorMsg(error);

      if (error instanceof TimeoutError) {
        Toast.show({
          type: "success",
          text1: i18nDictionary.popups.errors.computationOngoing(),
          text2: errorMsg,
        });
      } else {
        Toast.show({
          type: "error",
          text1: i18nDictionary.popups.errors.measurementErrorTitle(),
          text2: errorMsg,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  async function postMeasurement() {
    // There's a redirect when volume is selected and CORS complains about it.
    // Therefore, the measurementType is not sent in the URL for that case.
    const urlSuffix =
      measurementType !== MeasurementType.Volume ? `${measurementType}/` : "";
    const url = `devices/${deviceId}/read/${urlSuffix}`;

    const response = await api.post<VolumeResultDTO | DimensionsResultDTO>(
      url,
      {
        timeout: 27000,
        ...(container ? { containerId: container } : {}),
      }
    );

    const timeoutHappenend =
      !(MeasurementType.Volume in response.data) &&
      !(MeasurementType.Dimensions in response.data);
    if (timeoutHappenend) {
      throw new TimeoutError(i18nDictionary.popups.errors.computationTimeout());
    }

    return response.data;
  }

  React.useEffect(() => {
    dispatch(setTenantData());
  }, []);

  React.useEffect(() => {
    if (!deviceId) setDeviceId(deviceList[0]?.value);
    if (!container) setContainer(containerList[0]?.value);
  }, [deviceList, containerList]);

  React.useEffect(() => {
    if (defaultMeasurementType) {
      setMeasurementType(defaultMeasurementType);
    }
  }, [defaultMeasurementType]);

  return (
    <VStack padding="6" flexGrow="1">
      <Text bold fontSize="3xl">
        {i18nDictionary.screens.Measures.newMeasurement()}
      </Text>

      <VStack marginTop="8" space="4" flexGrow="1">
        <Dropdown
          selectedValue={deviceId}
          values={deviceList}
          onValueChange={dropdownChangeHandler<number>(setDeviceId)}
          label={i18nDictionary.screens.Measures.device()}
        />
        <Dropdown
          selectedValue={measurementType}
          values={measurementTypeList}
          onValueChange={dropdownChangeHandler<MeasurementType>(
            setMeasurementType
          )}
          label={i18nDictionary.screens.Measures.measurementType()}
        />
        {displayContainerDropdown && (
          <Dropdown
            selectedValue={container}
            values={containerList}
            onValueChange={dropdownChangeHandler<string>(setContainer)}
            label={i18nDictionary.screens.Measures.container()}
          />
        )}
        {measurement && <MeasurementResult measurement={measurement} />}
      </VStack>

      {pointcloudUrl && (
        <Button
          minWidth={35}
          isLoading={downloadLoading}
          variant="outline"
          endIcon={
            <Icon as={Ionicons} name="cloud-download-outline" size="sm" />
          }
          onPress={async () => await handleDownload3dView()}
          _text={{ fontWeight: "semibold" }}
          marginBottom="4"
        >
          {i18nDictionary.screens.Measures.download3DView()}
        </Button>
      )}
      <Button
        minWidth={35}
        isLoading={loading}
        onPress={async () => await handleNewMeasurement()}
        _text={{ fontWeight: "semibold" }}
      >
        {i18nDictionary.screens.Measures.newMeasurement()}
      </Button>
    </VStack>
  );
}

const downloadFile = async (fileUrl: string) => {
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.src = fileUrl;
  document.body.appendChild(iframe);
};

async function handleDownload(
  fileUrl: string,
  retryAttempts: number,
  retryDelay: number
) {
  for (let attempt = 1; attempt <= retryAttempts; attempt++) {
    try {
      const response = await fetch(fileUrl, { method: "HEAD" });
      if (response.ok) {
        await downloadFile(fileUrl);
        return; // Exit the function on successful download
      }

      // Wait for retryDelay before trying again
      await new Promise((resolve) => setTimeout(resolve, retryDelay));
    } catch (error) {
      console.log(error);
    }
  }

  throw new Error(`Failed to download file: ${fileUrl}`);
}
