import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import * as Updates from "expo-updates";
import * as Clipboard from "expo-clipboard";

import Clip from "../svg/clip";
import { version } from "../../package.json";

interface VersionProps {
  color: string;
  versionText: string;
}

const VersionSideMenu = ({ color, versionText }: VersionProps) => {

    return (
    <TouchableOpacity
      onPress={async () => {
        await Clipboard.setStringAsync(
          "version: " +
            (Updates.runtimeVersion === null ? version : Updates.runtimeVersion)
        );
      }}
      style={styles.item}
    >
      <View style={styles.iconContainer}>
        <Clip width={22} height={22} color={color} />
      </View>
      <View>
        <View style={styles.item}>
          <Text style={styles.labelVersion}>{versionText}: </Text>
          <Text style={styles.labelVersionValue}>
            {Updates.runtimeVersion === null ? version : Updates.runtimeVersion}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginVertical: 4,
  },
  labelVersion: {
    marginLeft: 4,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, .87)",
  },
  labelVersionValue: {
    marginHorizontal: 4,
    color: "rgba(0, 0, 0, .87)",
  },
  iconContainer: {
    marginLeft: 16,
    width: 24,
    alignItems: "center",
  },
});

export default VersionSideMenu;
