import type { StackScreenProps } from "@react-navigation/stack";
import ChallengeName from "@aws-amplify/auth";
import ICognitoUserPoolData from "@aws-amplify/auth";
import { NavigatorScreenParams } from "@react-navigation/native";
import { DrawerScreenProps } from "@react-navigation/drawer";

export type RootStackParamList = {
  Auth: undefined;
  Main: NavigatorScreenParams<RootDrawerParamList>;
  Categories: { orderNumber: string };
  Measurements: { orderNumber: string; categoryCode?: CategoryCode };
  Measure: undefined;
  Drawer: undefined;
  Scheduled: undefined;
  Completed: undefined;
  CreateService: undefined;
  NotFound: undefined;
  ForgotPassword: undefined;
};

export type RootDrawerParamList = {
  Home: undefined;
  ChangePassword: undefined;
  Dashboard: undefined;
};

export type RootTabParamList = {
  Home: undefined;
  ChangePassword: undefined;
  Dashboard: undefined;
};

export type RootStackScreenProps<T extends keyof RootStackParamList> =
  StackScreenProps<RootStackParamList, T>;

export type RootDrawerScreenProps<T extends keyof RootDrawerParamList> =
  DrawerScreenProps<RootDrawerParamList, T>;

declare global {
  namespace ReactNavigation {
    interface RootParamList extends RootStackParamList {}
  }
}

export interface ServiceCardState {
  id: string;
  scheduledStart?: string;
}

export interface CategoryCardState {
  name: string;
  volume: string;
}

export interface MeasurementCardState {
  time: string;
  volume: string;
}

export type CategoryCode = string;

export interface Measurement {
  volume: string;
  createdAt: string;
  updatedAt: string;
  categoryCode?: string;
}

export interface WasteCategory {
  categoryCode: string;
  categoryName: {
    [key: string]: string;
  };
  expectedVolume: number;
  realVolume: number;
}

export enum ServiceStatusEnum {
  Scheduled = "scheduled",
  Completed = "completed",
}

export enum MeasurementType {
  Volume = "volume",
  Dimensions = "dimensions",
}

export type CategoriesRecord = Record<CategoryCode, WasteCategory>;

export interface Service {
  orderNumber: string;
  scheduledStart: Date;
  status: ServiceStatusEnum;
  wasteCategories: WasteCategory[];
  measurements: Measurement[];
}

export interface UserState {
  userId: string;
  name: string;
  email: string;
  token: string;
  logged: true;
}

export interface TenantConfig {
  usesOrderNumber: boolean;
  wasteCategories: Array<{
    code: string;
    name: string;
  }>;
  defaultMeasurementType?: MeasurementType;
  locales: Intl.LocalesArgument;
  dateTimeOptions: Intl.DateTimeFormatOptions;
}

export interface TenantState {
  id: number;
  name: string;
  config: TenantConfig;
}

export interface SessionData {
  user: {
    id: number;
    tenant_id: number;
  };
  tenant: TenantState;
  categories: [
    {
      id: string;
      value: string;
      code: string;
      name: string;
    }
  ];
  containers: [
    {
      id: number;
      name: string;
    }
  ];
  devices: [
    {
      id: number;
      name: string;
    }
  ];
}

export interface Option<T> {
  label: string;
  value: T;
}
export type CategoryState<T> = Array<Option<T>>;

export const CATEGORIES = [
  "A - Plásticos",
  "B - Desecho de Alimentos",
  "C - Desechos domésticos",
  "D -Aceite de Cocina",
];
export const CATEGORIES_SET = new Set<string>(CATEGORIES);
export const DEFAULT_CATEGORIES = CATEGORIES[0];
export type CategoryValue = (typeof CATEGORIES)[number];

export const CONTAINERS = ["G2025", "G1206", "G2029", "G2027"];
export const CONTAINERS_SET = new Set<string>(CONTAINERS);
export const DEFAULT_CONTAINERS = CONTAINERS[0];
export type ContainerValue = (typeof CONTAINERS)[number];

export const DEVICES = [
  "Simulator_IKUSI",
  "Scanner_barcelona",
  "simulator_stage",
  "Scanner_aveiro",
];
export const DEVICES_SET = new Set<string>(DEVICES);
export const DEFAULT_DEVICES = DEVICES[0];
export type DeviceValue = (typeof DEVICES)[number];
