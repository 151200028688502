import { Dictionary } from "../index";

const pt: Dictionary = {
  screens: {
    NotFound: {
      message: "Página não encontrada",
    },
    Measures: {
      scale: "Escala",
      category: "Categoria",
      container: "Container",
      device: "Dispositivo",
      measurementType: "Tipo de medição",
      tare: "Tara",
      submit: "Medir",
      dashboard: "Dashboard",
      newMeasurement: "Nova medida",
      volume: "Volume",
      dimensions: "Dimensões",
      time: "Horário",
      download3DView: "Descarregar dados 3D",
      errorMessageSubmit:
        "Erro ao submeter dados das medidas solicitadas, tente novamente!",
      errorMessageUnknownDevice: "Dispositivo desconhecido",
      errorMessageUnknownContainer: "Contêiner desconhecido",
      errorMessageUnboundTimeout: "Tempo esgotado",
      errorMessageUnauthorizedRequest: "Não autorizado",
      errorMessageDeviceNotReady:
        "O servidor não está pronto para iniciar uma nova medição de volume",
      errorMessageVolumeComputation: "Erro de cálculo de volume",
      errorMessageUnexpected: "Houve um problema no processamento do servidor",
      errorMessageUnknownUsecase: "Caso de uso desconhecido",
      errorMessageUnboundDelay: "Atraso no resposta do servidor",
      errorMessageInvalidContentType: "Formato de conteúdo inválido",
    },
    Home: {
      emptyServiceList: "Clique no botão '+' para agendar um novo serviço.",
      emptyCompletedServices: "Nenhum serviço completado.",
      scheduled: "Agendado",
      completed: "Finalizado",
      version: "Versão",
      helpPage: "https://goclever.io/wasteapp-pt/",
      help: "Ajuda",
    },
    CreateService: {
      header: "Registro de Descarte de Resíduos",
      orderNumber: "Número de Pedido",
      register: "Registrar",
      cancel: "Cancelar",
    },
    ServiceCategories: {
      category: "Categoria",
      measured: "Medido",
      completeService: "Finalizar serviço",
      emptyCategoriesList: "Nenhuma categoria disponível.",
    },
    ServiceMeasurements: {
      declared: "Declarado",
      measured: "Medido",
      measurement: "Medição",
      volume: "Volume",
      newMeasurement: "Nova medição",
      emptyMeasurementsList: "Nenhuma medição feita.",
      container: "Contentor",
      device: "Dispositivo",
    },
  },
  cognito: {
    SignIn: "Iniciar sessão",
    SignOut: "Encerrar sessão",
    Username: "E-mail",
    Password: "Senha",
    Email: "E-mail",
    Confirm: "Confirmar",
    Cancel: "Cancelar",
    Submit: "Enviar",
    Skip: "Pular",
    Verify: "Verificar",
    Code: "Código",
    Anonymous: "Acessar de forma anônima",
    IncorrectLogin: "Nome de usuário ou senha incorreta",
    ChangePassword: "Alteração de senha",
    OldPassword: "Senha anterior",
    NewPassword: "Senha nova",
    ConfirmPassword: "Repita a senha",
    ForgotPassword: "Esqueceu a senha?",
    EmailConfirmCode: "Alteração da senha",
    ConfirmationCode: "Código de confirmação",
    FirstAccess: "Primeiro Acesso",
    SuccessMessageChangePassword: "Senha alterada com sucesso!",
    SuccessMessageSubmit: "Medidas enviadas com sucesso!",
    SuccessMessageTara: "Tara enviada com sucesso!",
    ErrorMessageDifferentPasswords:
      "A confirmação da senha não corresponde com a nova senha",
    ErrorEmptyFieldsMessage:
      "Campos incompletos, por favor preencha todos os campos e tente novamente!",
    ErrorGeneralMessage: "Houve um erro, tente novamente!",
    NotAuthorizedException: "Senha incorreta, tente novamente!",
    UserNotFoundException:
      "Combinação de nome de usuário/id de cliente não encontrada.",
    CodeMismatchException:
      "Código de verificação inválido fornecido. Tente novamente.",
    InvalidPasswordException: "A senha não é longa o suficiente",
    InvalidParameterException: "Campo de usuário e/ou senha vazios",
    PasswordResetRequiredException:
      "Redefinição de senha necessária para o usuário",
  },
  popups: {
    errors: {
      computationOngoing: "Cálculo em andamento",
      computationTimeout: "Continunará em segundo plano!",
      downloadErrorTitle: "Erro de download",
      downloadErrorText: "Por favor, tente novamente mais tarde.",
      fetchErrorTitle: "Erro",
      fetchErrorText: "Lista de serviços indisponível.",
      registrationErrorTitle: "Erro de registro",
      registrationErrorText: "Serviço não pôde ser registrado.",
      updateErrorTitle: "Erro de atualização",
      updateErrorText: "Serviço não pôde ser finalizado.",
      measurementErrorTitle: "Erro de medição",
      measurementDeleteErrorTitle: "Erro",
      measurementDeleteErrorText: "Medição não pôde ser deletada.",
      measurementPatchErrorTitle: "Erro",
      measurementPatchErrorText:
        "Medição não pôde ser atualizada. Por favor, tente novamente.",
      orderNumberCanNotBeEmpty: "Por favor, insira um número de pedido",
      orderNumberTooLong: "Número de pedido muito longo",
      volumeCanNotBeEmpty: "O volume não pode estar vazio",
      volumeHasInvalidChars: "O volume contém caracteres inválidos.",
      volumeInWrongFormat: "O volume deve estar no formato 0.00",
      volumeInvalid: "Volume inválido",
      volumeOutOfBounds: "O volume deve estar entre 0 e 100",
    },
    success: {
      volume: "Volume",
      deletedVolumeTitle: "Volume deletado",
    },
  },
};

export default pt;
