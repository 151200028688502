import React from "react";
import {
  ActivityIndicator,
  StyleSheet,
  Keyboard,
  TouchableOpacity,
  View,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Text from "../../../shared/atoms/Text";
import { Sizes } from "../../../shared/constants/sizes";
import Input from "../../../shared/molecules/Input";
import i18nDictionary from "../../i18n";
import { Theme } from "../../styles";

interface ConfirmCodeProps {
  sendPwdChange: () => void;
  email: string;
  setEmail: (value: string) => void;
  confirmationCode: string;
  setConfirmationCode: (value: string) => void;
  newPassword: string;
  setNewPassword: (value: string) => void;
  newPasswordConfirm: string;
  setNewPasswordConfirm: (value: string) => void;
  loadingButton: boolean;
}
const ConfirmCode = ({
  sendPwdChange,
  email,
  setEmail,
  confirmationCode,
  setConfirmationCode,
  newPassword,
  setNewPassword,
  newPasswordConfirm,
  setNewPasswordConfirm,
  loadingButton,
}: ConfirmCodeProps) => {
  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Text
        style={{
          fontSize: 48,
          color: Theme.colors.title,
          fontWeight: "700",
          marginVertical: 20,
        }}
      >
        goclever
      </Text>

      <Text
        style={{
          fontSize: Sizes.h1,
          fontWeight: "700",
          color: Theme.colors.title,
        }}
      >
        {i18nDictionary.cognito.ForgotPassword()}
      </Text>

      <View style={{ width: "100%" }}>
        <Input
          onChange={(text) => setEmail(text.toLowerCase())}
          value={email}
          error={false}
          label={i18nDictionary.cognito.Email()}
        />
        <Input
          onChange={setConfirmationCode}
          value={confirmationCode}
          error={false}
          label={i18nDictionary.cognito.ConfirmationCode()}
        />
        <Input
          onChange={setNewPassword}
          value={newPassword}
          error={false}
          label={i18nDictionary.cognito.NewPassword()}
          secureTextEntry={true}
        />
        <Input
          onChange={setNewPasswordConfirm}
          value={newPasswordConfirm}
          error={false}
          label={i18nDictionary.cognito.ConfirmPassword()}
          secureTextEntry={true}
          onSubmitEditing={() => {
            Keyboard.dismiss();
            sendPwdChange();
          }}
        />
      </View>

      <View style={{ marginVertical: 10 }}>
        <TouchableOpacity
          onPress={() => sendPwdChange()}
          style={styles.loginButton}
        >
          {loadingButton ? (
            <ActivityIndicator color={Theme.colors.primary} size={25} />
          ) : (
            <Text
              style={{ fontSize: Sizes.body3, color: Theme.colors.primary }}
            >
              {i18nDictionary.cognito.Submit()}
            </Text>
          )}
        </TouchableOpacity>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: Theme.sizes.paddingM,
  },
  input: {
    borderColor: "gray",
    backgroundColor: "#FFF",
    width: "100%",
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  loginButton: {
    borderRadius: Theme.sizes.borderRadiusM,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.colors.title,
    height: Theme.sizes.heightS,
    padding: Theme.sizes.paddingS,
    margin: 10,
  },
});

export default ConfirmCode;
