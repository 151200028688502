import React from 'react'
import Svg, { Path } from 'react-native-svg';
const Clip = (props: {width: number, height: number, color: string}) => {

  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 726 854" fill="none" >
      <Path d="M85.6058 853.667C62.1391 853.667 42.0431 845.318 25.3178 828.621C8.6209 811.896 0.272461 791.8 0.272461 768.333V171H85.6058V768.333H554.939V853.667H85.6058ZM256.272 683C232.806 683 212.724 674.651 196.027 657.955C179.302 641.229 170.939 621.133 170.939 597.667V85.6666C170.939 62.1999 179.302 42.1039 196.027 25.3786C212.724 8.68169 232.806 0.333252 256.272 0.333252H640.272C663.739 0.333252 683.835 8.68169 700.56 25.3786C717.257 42.1039 725.606 62.1999 725.606 85.6666V597.667C725.606 621.133 717.257 641.229 700.56 657.955C683.835 674.651 663.739 683 640.272 683H256.272ZM256.272 597.667H640.272V85.6666H256.272V597.667ZM256.272 597.667V85.6666V597.667Z" fill={props.color} />
    </Svg>
  )
}

export default Clip; 
