import React, { useEffect, useState, useCallback } from "react";
import { StyleSheet, View, Platform } from "react-native";
import { Theme } from "../../styles";
import { RootStackScreenProps } from "../../types";
import Login from "./Login";
import { Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";
import FirstAccess from "./FirstAccess";
import i18nDictionary from "../../i18n";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import Toast from "react-native-toast-message";
import { useAppDispatch } from "../../store/hooks";
import { selectAuth, setUserAuthData, userLogin } from "../../store/authSlice";
import { useSelector } from "react-redux";
// import { checkUpdates } from "../../services/updates";
// import * as Sentry from "sentry-expo";
// import * as SplashScreen from "expo-splash-screen";

Auth.configure(awsconfig);

const AuthFlow = ({ navigation }: RootStackScreenProps<"Auth">) => {
  const dispatch = useAppDispatch();
  const authRedux = useSelector(selectAuth());

  const [isFirstLogin, setIsFirstLogin] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [authDetailsState, setAuthDetailsState] =
    useState<AuthenticationDetails>();

  //Login page state
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // const [appIsReady, setAppIsReady] = useState(false);

  // useEffect(() => {
  //   async function prepare() {
  //     try {
  //       const authUser = await Auth.currentAuthenticatedUser();
  //       await new Promise((resolve) => setTimeout(resolve, 1000));
  //     } catch (e) {
  //       console.warn(e);
  //     } finally {
  //       setAppIsReady(true);
  //     }
  //   }
  //   prepare();
  // }, []);

  // const onLayoutRootView = useCallback(async () => {
  //   if (appIsReady) {
  //     await SplashScreen.hideAsync();
  //   }
  // }, [appIsReady]);

  useEffect(() => {
    if (authRedux.user === null || isFirstLogin) {
      setIsFirstLogin(authRedux.firstLogin);
      if (!authRedux.loading) checkSession();
    } else {
      navigation.navigate("Main", { screen: "Home" });
      resetFields();
    }
  }, [authRedux]);

  const checkSession = async () => {
    try {
      const authUser = await Auth.currentAuthenticatedUser();
      resetFields();
      navigation.navigate("Main", { screen: "Home" });
    } catch (error) {
      setLoadingButton(false);
    }
  };

  const resetFields = () => {
    setTimeout(() => {
      setEmail("");
      setPassword("");
      setLoadingButton(false);
    }, 1500);
  };

  const signIn = async (email: string, password: string) => {
    dispatch(userLogin({ type: "pending" }));
    setLoadingButton(true);
    try {
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      setAuthDetailsState(authDetails);
      const cognitoPool = {
        UserPoolId: awsconfig.aws_user_pools_id,
        ClientId: awsconfig.aws_user_pools_web_client_id,
      };
      const up = new CognitoUserPool(cognitoPool);

      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: up,
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async function (result) {
          const user = await Auth.signIn(email, password);
          if (result !== null) {
            dispatch(
              setUserAuthData({
                firstLogin: false,
                authData: result,
                cognito: cognitoUser,
                type: "success",
              })
            );
            setTimeout(() => {
              navigation.navigate("Main", { screen: "Home" });
            }, 500);
          }
        },

        onFailure: function (err) {
          setErrorMsg(
            //@ts-ignore
            i18nDictionary.cognito[err.name] !== undefined
              ? //@ts-ignore
                i18nDictionary.cognito[err.name]()
              : i18nDictionary.cognito.ErrorGeneralMessage()
          );
          dispatch(userLogin({ type: "reject" }));
        },

        newPasswordRequired: function (result) {
          setIsFirstLogin(true);
          delete result.email_verified;
          result.name = result.email;
          delete result.email;
          dispatch(
            setUserAuthData({
              firstLogin: true,
              authData: result,
              cognito: cognitoUser,
              type: "success",
            })
          );
        },
      });
    } catch (e) {
      setLoadingButton(false);
      setErrorMsg(i18nDictionary.cognito.IncorrectLogin());
      dispatch(userLogin({ type: "reject" }));
    }
  };

  const submitNewPassword = async (password: string, newPassword: string) => {
    setLoadingButton(true);
    if (password !== newPassword) {
      setErrorMsg(i18nDictionary.cognito.ErrorMessageDifferentPasswords());
      setLoadingButton(false);
      return;
    }

    authRedux.cognitoUser?.authenticateUser(authDetailsState, {
      newPasswordRequired: function () {
        authRedux.cognitoUser?.completeNewPasswordChallenge(
          newPassword,
          authRedux.user,
          {
            onSuccess: () => {
              setErrorMsg("");
              setLoadingButton(false);
              setIsFirstLogin(false);
              setPassword("");
              if (authDetailsState) {
                signIn(authDetailsState.getUsername(), newPassword);
              }
            },

            onFailure: function (err: any) {
              setLoadingButton(false);
              setErrorMsg(
                //@ts-ignore
                i18nDictionary.cognito[err.name] === undefined
                  ? i18nDictionary.cognito.ErrorGeneralMessage()
                  : //@ts-ignore
                    i18nDictionary.cognito[err.name]()
              );
            },
          }
        );
      },
    });
  };

  const forgotPwd = () => {
    navigation.navigate("ForgotPassword");
  };

  // if (!appIsReady) {
  //   return null;
  // }

  return (
    <View style={styles.safeArea}>
      {/* <View style={styles.safeArea} onLayout={onLayoutRootView}> */}
      {isFirstLogin ? (
        <FirstAccess
          loadingButton={loadingButton}
          submitNewPassword={submitNewPassword}
          errorMsg={errorMsg}
        />
      ) : (
        <Login
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          loadingButton={loadingButton}
          errorMsg={errorMsg}
          signIn={signIn}
          forgotPwd={forgotPwd}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    alignItems: "center",
    backgroundColor: Theme.colors.primary,
    flex: 1,
  },
});

export default AuthFlow;
