import React, { useRef, useState } from "react";
import { Dimensions, StyleSheet, TouchableOpacity, View } from "react-native";

import {
  Container,
  Content,
  ContentButton,
  Return,
  ReturnButton,
  Logo,
} from "./styles";

import { Theme } from "../../styles";

// libs
import Ellipsis from "../../../shared/svg/ellipsis";
import ArrowBack from "../../../shared/svg/arrow_back";
import Text from "../../../shared/atoms/Text";
import { Sizes } from "../../../shared/constants/sizes";

const HeaderBar = (props: {
  hasLogo: string;
  goBack: boolean;
  nextStep?: Function;
  submit?: Function;
  options?: Function;
}) => {
  return (
    <View>
      <Container>
        <Content>
          <TouchableOpacity
            onPress={() => (props.nextStep ? props.nextStep() : null)}
          >
            {props.hasLogo !== "false" && props.hasLogo !== "true" ? (
              <ArrowBack width={35} height={35} color={Theme.colors.title} />
            ) : (
              <Ellipsis width={25} height={25} color={Theme.colors.title} />
            )}
          </TouchableOpacity>

          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              width: "90%",
            }}
          >
            {props.hasLogo === "true" ? (
              <Text
                style={{
                  fontSize: 30,
                  color: "#fff",
                  fontWeight: "700",
                }}
              >
                goclever
              </Text>
            ) : (
              props.hasLogo !== "false" && (
                <Text
                  style={{
                    fontSize: Sizes.h1,
                    color: Theme.colors.title,
                    fontWeight: "700",
                  }}
                >
                  {props.hasLogo}
                </Text>
              )
            )}
          </View>
        </Content>
      </Container>
    </View>
  );
};

const styles = StyleSheet.create({
  dropdown: {
    flex: 1,
    top: 32,
    left: 8,
  },
});

export default HeaderBar;
