import axios, { AxiosRequestConfig } from "axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Auth } from "aws-amplify";
import awsconfig from "../aws-exports";

Auth.configure(awsconfig);

export async function getJwtToken(): Promise<string> {
  const userSession = await AsyncStorage.getItem("@GOCLEVER_VOLUME/AUTH");
  const token = JSON.parse(userSession).authData?.accessToken?.jwtToken;

  return token;
}

export async function getAuthConfig(): Promise<AxiosRequestConfig> {
  return {
    headers: {
      Authorization: await getJwtToken(),
    },
  };
}

const baseURL =
  process.env.EXPO_API_BASE_URL !== undefined // Defined in AWS Amplify console
    ? process.env.EXPO_API_BASE_URL
    : "https://api.sense.staging.goclever.io";

export const api = axios.create({
  baseURL,
});

api.interceptors.request.use(
  async (config) => {
    if (config.headers) {
      config.headers['Authorization'] = await getJwtToken();
    }
    return config;
  },
  error => Promise.reject(error),
);
