import React from "react";
import Svg, { Path } from "react-native-svg";
const Help = (props: { width: number; height: number; color: string }) => {
  return (
    <Svg
      width={props.width}
      height={props.height}
      viewBox="0 0 768 768"
      fill="none"
    >
      <Path
        d="M85.3333 768C61.8667 768 41.7707 759.652 25.0453 742.955C8.34844 726.229 0 706.133 0 682.667V85.3333C0 61.8667 8.34844 41.7707 25.0453 25.0453C41.7707 8.34844 61.8667 0 85.3333 0H682.667C706.133 0 726.229 8.34844 742.955 25.0453C759.652 41.7707 768 61.8667 768 85.3333V682.667C768 706.133 759.652 726.229 742.955 742.955C726.229 759.652 706.133 768 682.667 768H85.3333ZM85.3333 682.667H682.667V85.3333H85.3333V682.667ZM384 640C398.933 640 411.548 634.837 421.845 624.512C432.171 614.215 437.333 601.6 437.333 586.667C437.333 571.733 432.171 559.118 421.845 548.821C411.548 538.496 398.933 533.333 384 533.333C369.067 533.333 356.452 538.496 346.155 548.821C335.829 559.118 330.667 571.733 330.667 586.667C330.667 601.6 335.829 614.215 346.155 624.512C356.452 634.837 369.067 640 384 640ZM426.667 439.467C428.8 428.089 432.54 418.133 437.888 409.6C443.207 401.067 453.689 388.978 469.333 373.333C495.644 347.022 513.422 325.689 522.667 309.333C531.911 292.978 536.533 274.489 536.533 253.867C536.533 217.6 523.207 187.563 496.555 163.755C469.874 139.918 436.267 128 395.733 128C365.156 128 337.963 134.229 314.155 146.688C290.318 159.118 272 175.644 259.2 196.267C252.8 206.222 251.022 216.704 253.867 227.712C256.711 238.748 263.111 246.4 273.067 250.667C283.022 254.933 292.807 255.104 302.421 251.179C312.007 247.282 319.644 240.711 325.333 231.467C332.444 221.511 342.044 213.86 354.133 208.512C366.222 203.193 378.667 200.533 391.467 200.533C411.378 200.533 427.378 205.867 439.467 216.533C451.556 227.2 457.6 240.711 457.6 257.067C457.6 270.578 453.518 284.089 445.355 297.6C437.163 311.111 424.533 325.333 407.467 340.267C388.978 356.622 374.756 373.333 364.8 390.4C354.844 407.467 349.867 423.467 349.867 438.4C349.867 448.356 353.607 456.889 361.088 464C368.54 471.111 377.6 474.667 388.267 474.667C398.222 474.667 406.585 471.282 413.355 464.512C420.096 457.771 424.533 449.422 426.667 439.467Z"
        fill={props.color}
      />
    </Svg>
  );
};

export default Help;
