import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { MeasurementType, SessionData, TenantState } from "../types";
import { RootState } from ".";
import { api } from "../services/api";

const initialState: { sessionData?: SessionData } = {};

export const fetchTenantData = createAsyncThunk(
  "tenantData/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const tenantData = await AsyncStorage.getItem("@GOCLEVER_VOLUME/TENANT");
      return tenantData;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const setTenantData = createAsyncThunk(
  "tenantData/save",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.get<{ response: SessionData }>("/user");

      await AsyncStorage.setItem(
        "@GOCLEVER_VOLUME/TENANT",
        JSON.stringify(data.response)
      );
      return data.response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const removeTenantData = createAsyncThunk(
  "tenantData/remove",
  async (_, { rejectWithValue }) => {
    try {
      await AsyncStorage.removeItem("@GOCLEVER_VOLUME/TENANT");
      return;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const tenantSlice = createSlice({
  name: "tenantData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenantData.fulfilled, (state, action) => {
        const { payload } = action;
        state.sessionData = payload !== null && JSON.parse(payload);
      })
      .addCase(fetchTenantData.rejected, (state, action) => {
        state.sessionData = undefined;
      })
      .addCase(setTenantData.fulfilled, (state, action) => {
        const { payload } = action;
        state.sessionData = payload;
      })
      .addCase(setTenantData.rejected, (state, action) => {
        state.sessionData = undefined;
      })
      .addCase(removeTenantData.fulfilled, (state, action) => {
        state = {};
      })
      .addCase(removeTenantData.rejected, (state, action) => {
        state = {};
      });
  },
});

export const selectUserData = (id?: string) => (state: RootState) =>
  state.tenant.sessionData;
export const selectCategories = (id?: string) => (state: RootState) =>
  state.tenant.sessionData?.categories;
export const selectContainers = (
  state: RootState
): Array<{ label: string; value: string }> => {
  const containers = state.tenant?.sessionData?.containers.map((container) => ({
    label: container.name,
    value: container.name,
  }));
  if (containers === undefined) {
    return [];
  }
  return containers;
};
export const selectTenantId = (state: RootState): number | undefined => {
  const tenant = state.tenant?.sessionData?.tenant;
  return tenant === undefined ? undefined : tenant.id;
};
export const selectDevices = (
  state: RootState
): Array<{ label: string; value: number }> => {
  const devices = state.tenant?.sessionData?.devices.map((device) => ({
    label: device.name,
    value: Number(device.id),
  }));
  if (devices === undefined) {
    return [];
  }
  return devices;
};
export const selectTenant = (state: RootState): TenantState | undefined => {
  return state.tenant?.sessionData?.tenant;
};
export const selectDefaultMeasurementType = (
  state: RootState
): MeasurementType => {
  const measurementType =
    state.tenant?.sessionData?.tenant.config.defaultMeasurementType;
  return measurementType ?? MeasurementType.Volume;
};
