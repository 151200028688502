import React from 'react'
import Svg, { Path } from 'react-native-svg';
const Ellipsis = (props: { width: number, height: number, color: string }) => {

  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 1024 1024" fill="none" >
      <Path d="M896 768H128V682.667H896V768ZM896 554.667H128V469.333H896V554.667ZM896 341.333H128V256H896V341.333Z" fill="white"/>
    </Svg>
  )
}

export default Ellipsis; 