import React from "react";
import { TouchableOpacity, View } from "react-native";
import NotFound404 from "../assets/svg/404";
import { GoText, HeaderBar } from "../components";
import i18nDictionary from "../i18n";
import { Theme } from "../styles";
import { RootStackScreenProps } from "../types";

const NotFound = ({ navigation }: RootStackScreenProps<"NotFound">) => {
  return (
    <>
      <HeaderBar goBack={false} hasLogo={"false"} />
      <View style={{ height: "100%", alignItems: "center" }}>
        <View style={{ margin: 50, alignItems: "center" }}>
          <NotFound404 width={300} height={300} />
          <GoText h1 bold titleColor>
            {i18nDictionary.screens.NotFound.message()}!
          </GoText>

          <TouchableOpacity
            onPress={() => navigation.navigate("Auth")}
            style={{
              paddingHorizontal: 10,
              width: "48%",
              paddingVertical: 15,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: Theme.sizes.borderRadiusXL,
              borderWidth: 2,
              borderColor: Theme.colors.title,
              backgroundColor: Theme.colors.primary,
              marginTop: 50,
            }}
          >
            <GoText h2 primaryColor>
              Home
            </GoText>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

export default NotFound;
