export const Sizes = {
    // font sizes
    h1: 30,
    h2: 24,
    h3: 20,
    h4: 16,
    h5: 14,
    h6: 13,
    body1: 30,
    body2: 22,
    body3: 16,
    body4: 14,
    body5: 13,
    body6: 12,

    paddingXL: 25,
    paddingL: 20,
    paddingM: 15,
    paddingS: 10,
    paddingXS: 5,

    marginXL: 25,
    marginL: 20,
    marginM: 15,
    marginS: 10,
    marginXS: 5,

    borderRadiusXL: 150,
    borderRadiusL: 50,
    borderRadiusM: 25,
    borderRadiusS: 5,

    heightXL: 150,
    heightL: 120,
    heightM: 55,
    heightS: 45,
    heightXS: 30,
}