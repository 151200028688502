import React from "react";
import {
  ActivityIndicator,
  StyleSheet,
  Keyboard,
  TouchableOpacity,
  View,
} from "react-native";
import Text from "../../../shared/atoms/Text";
import { Sizes } from "../../../shared/constants/sizes";
import Input from "../../../shared/molecules/Input";
import i18nDictionary from "../../i18n";
import { Theme } from "../../styles";

interface EmailConfirmCodeProps {
  sendCode: () => void;
  cancelFlow: () => void;
  email: string;
  setEmail: (email: string) => void;
  loadingButton: boolean;
}

const EmailConfirmCode = ({
  sendCode,
  cancelFlow,
  email,
  setEmail,
  loadingButton,
}: EmailConfirmCodeProps) => {
  return (
    <View style={styles.container}>
      <Text
        style={{
          fontSize: 48,
          color: Theme.colors.title,
          fontWeight: "700",
          marginVertical: 20,
        }}
      >
        goclever
      </Text>

      <Text
        style={{
          fontSize: Sizes.h1,
          fontWeight: "700",
          color: Theme.colors.title,
        }}
      >
        {i18nDictionary.cognito.ForgotPassword()}
      </Text>

      <View style={{ width: "100%" }}>
        <Input
          onChange={(text) => setEmail(text.toLowerCase())}
          value={email}
          label={i18nDictionary.cognito.Email()}
          error={false}
          onSubmitEditing={() => {
            Keyboard.dismiss();
            sendCode();
          }}
        />
      </View>

      <View
        style={{
          marginVertical: Theme.sizes.marginL,
          flexDirection: "row",
          width: "75%",
          justifyContent: "space-around",
        }}
      >
        <TouchableOpacity
          onPress={() => sendCode()}
          style={styles.sendCodeButton}
        >
          {loadingButton ? (
            <ActivityIndicator color={Theme.colors.title} size={25} />
          ) : (
            <Text style={{ fontSize: Sizes.body3, color: Theme.colors.title }}>
              {i18nDictionary.cognito.Submit()}
            </Text>
          )}
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => cancelFlow()}
          style={styles.cancelButton}
        >
          <Text style={{ fontSize: Sizes.body3, color: Theme.colors.title }}>
            {i18nDictionary.cognito.Cancel()}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: Theme.sizes.paddingM,
  },
  sendCodeButton: {
    width: "45%",
    borderRadius: Theme.sizes.borderRadiusM,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.colors.success,
    height: Theme.sizes.heightS,
    padding: Theme.sizes.paddingS,
  },
  cancelButton: {
    width: "45%",
    borderRadius: Theme.sizes.borderRadiusM,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.colors.error,
    height: Theme.sizes.heightS,
    padding: Theme.sizes.paddingS,
  },
});

export default EmailConfirmCode;
