import axios from "axios";
import i18nDictionary from "../../i18n";


export class TimeoutError extends Error {
  constructor(msg: string) {
      super(msg);

      // Set the prototype explicitly.
      Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}

export const getErrorMsg = (error): string => {
  if (error instanceof TimeoutError) {
    return error.message;
  }

  if (!axios.isAxiosError(error)) {
    console.log(error);
    return i18nDictionary.screens.Measures.errorMessageSubmit();
  }

  const { response: { status, data } = {} } = error;
  const errorId = data?.errorId;

  switch (status) {
    case 401:
      return i18nDictionary.screens.Measures.errorMessageUnauthorizedRequest();
    case 404:
      switch (errorId) {
        case 1:
          return i18nDictionary.screens.Measures.errorMessageUnknownDevice();
        case 2:
          return i18nDictionary.screens.Measures.errorMessageUnknownContainer();
        default:
          return i18nDictionary.screens.Measures.errorMessageUnknownUsecase();
      }
    case 406:
      return i18nDictionary.screens.Measures.errorMessageInvalidContentType();
    case 500:
      switch (errorId) {
        case 6:
          return i18nDictionary.screens.Measures.errorMessageVolumeComputation();
        case 7:
          return i18nDictionary.screens.Measures.errorMessageUnexpected();
        default:
          break;
      }
    case 503:
      return i18nDictionary.screens.Measures.errorMessageDeviceNotReady();
    default:
      break;
  }

  return i18nDictionary.screens.Measures.errorMessageSubmit();
};