import React from "react";
import { Theme } from "../../styles";

import { ButtonContainer, Container } from "./styles";

interface Props {
  onPress: () => void;
}

export function RoundButton({ onPress: handleOnPress }: Props): JSX.Element {
  return (
    <Container>
      <ButtonContainer
        icon="plus"
        size={36}
        iconColor={Theme.colors.backgroundLight}
        onPress={handleOnPress}
      />
    </Container>
  );
}
