import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import { Theme } from "../../styles";
import { RootStackScreenProps } from "../../types";
import { Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";
import i18nDictionary from "../../i18n";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import Toast from "react-native-toast-message";
import ConfirmCode from "./ConfirmCode";
import EmailConfirmCode from "./EmailConfirmCode";
import { useAppDispatch } from "../../store/hooks";
import { setUserAuthData, userLogin } from "../../store/authSlice";

Auth.configure(awsconfig);

const ForgotPassword = ({
  navigation,
}: RootStackScreenProps<"ForgotPassword">) => {
  const dispatch = useAppDispatch();

  const [confirmCodeSent, setConfirmCodeSent] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const cognitoPool = {
    UserPoolId: awsconfig.aws_user_pools_id,
    ClientId: awsconfig.aws_user_pools_web_client_id,
  };
  const up = new CognitoUserPool(cognitoPool);

  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: up,
  });

  const resetFields = () => {
    setEmail("");
    setConfirmationCode("");
    setNewPassword("");
    setNewPasswordConfirm("");
  };

  const sendCode = async () => {
    setLoadingButton(true);

    cognitoUser.forgotPassword({
      onSuccess: function (data) {
        setLoadingButton(false);
        setConfirmCodeSent(true);
      },
      onFailure: function (err) {
        setLoadingButton(false);
        setEmail("");
        Toast.show({
          type: "error",
          text1:
            //@ts-ignore
            i18nDictionary.cognito[err.name + "Title"] !== undefined
              ? //@ts-ignore
                i18nDictionary.cognito[err.name + "Title"]()
              : "Error",
          //@ts-ignore
          text2: i18nDictionary.cognito["ForcePwdChangeException"](),
        });
        navigation.goBack();
      },
    });
  };

  const signIn = async (email: string, password: string) => {
    setLoadingButton(true);
    try {
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      const cognitoPool = {
        UserPoolId: awsconfig.aws_user_pools_id,
        ClientId: awsconfig.aws_user_pools_web_client_id,
      };
      const up = new CognitoUserPool(cognitoPool);

      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: up,
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async function (result) {
          const user = await Auth.signIn(email, password);
          if (result !== null) {
            dispatch(
              setUserAuthData({
                firstLogin: false,
                authData: result,
                cognito: cognitoUser,
                type: "success",
              })
            );
            setTimeout(() => {
              navigation.navigate("Main", { screen: "Home" });
            }, 500);
          }
        },

        onFailure: function (err) {
          dispatch(userLogin({ type: "reject" }));
        },
      });
    } catch (e) {
      setLoadingButton(false);
      dispatch(userLogin({ type: "reject" }));
    }
  };

  const sendPwdChange = async () => {
    setLoadingButton(true);
    if (newPasswordConfirm !== newPassword) {
      Toast.show({
        type: "error",
        text1: "Error",
        text2: i18nDictionary.cognito.ErrorMessageDifferentPasswords(),
      });
      setLoadingButton(false);
      return;
    }
    if (
      newPassword === "" ||
      newPasswordConfirm === "" ||
      confirmationCode === "" ||
      email === ""
    ) {
      Toast.show({
        type: "error",
        text1: "Error",
        text2: i18nDictionary.cognito.ErrorEmptyFieldsMessage(),
      });
      setLoadingButton(false);
      return;
    }
    cognitoUser.confirmPassword(confirmationCode, newPassword, {
      onSuccess() {
        signIn(email, newPassword);
        setTimeout(() => {
          Toast.show({
            type: "success",
            text1: "Hey",
            text2: i18nDictionary.cognito.SuccessMessageChangePassword(),
          });
        }, 750);
      },
      onFailure(err) {
        setLoadingButton(false);
        resetFields();
        Toast.show({
          type: "error",
          text1:
            //@ts-ignore
            i18nDictionary.cognito[err.name + "Title"] !== undefined
              ? //@ts-ignore
                i18nDictionary.cognito[err.name + "Title"]()
              : "Error",
          //@ts-ignore
          text2: i18nDictionary.cognito[err.name](),
        });
      },
    });
  };

  const cancelFlow = () => {
    resetFields();
    navigation.navigate("Auth");
  };

  return (
    <View style={styles.safeArea}>
      {confirmCodeSent ? (
        <ConfirmCode
          sendPwdChange={sendPwdChange}
          email={email}
          setEmail={setEmail}
          confirmationCode={confirmationCode}
          setConfirmationCode={setConfirmationCode}
          newPassword={newPassword}
          setNewPassword={setNewPassword}
          newPasswordConfirm={newPasswordConfirm}
          setNewPasswordConfirm={setNewPasswordConfirm}
          loadingButton={loadingButton}
        />
      ) : (
        <EmailConfirmCode
          sendCode={sendCode}
          cancelFlow={cancelFlow}
          email={email}
          setEmail={setEmail}
          loadingButton={loadingButton}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  safeArea: {
    height: "100%",
    alignItems: "center",
    backgroundColor: Theme.colors.primary,
    flex: 1,
  },
});

export default ForgotPassword;
