import React, { useState } from 'react'
import { View, TextInput, TextInputProps, StyleSheet, KeyboardTypeOptions, TouchableOpacity } from 'react-native'

// Components
import { FloatingLabel } from "../atoms/FloatingLabel"
import { Sizes } from '../constants/sizes'

// Constants
import Eye from '../svg/eye'
import EyeSlash from '../svg/eye_slash'

type InputProps = {
    onChange: (value: string) => void
    value?: string,
    label: string,
    secureTextEntry?: boolean,
    keyboardType?: KeyboardTypeOptions,
    selectTextOnFocus?: boolean,
    onSubmitEditing?: () => void,
    error: boolean,
    editable?: boolean
} & TextInputProps

const Input = ({ onChange, value, label, secureTextEntry, keyboardType, selectTextOnFocus, onSubmitEditing, error, editable, ...props }: InputProps) => {
    const [security, setSecurity] = useState(secureTextEntry)

    return (
        <View>
            <View style={{ ...styles.container, borderColor: error ? "#E83F5b" : "#B5B5B5" }}>
                <FloatingLabel text={label} backgroundColor="#FFF" color='#000' />
                <View style={{ flexDirection: 'row' }}>
                    <TextInput
                        value={value}
                        style={{ ...styles.input}}
                        onChangeText={onChange}
                        blurOnSubmit={false}
                        multiline={false}
                        selectTextOnFocus={selectTextOnFocus}
                        keyboardType={keyboardType}
                        onSubmitEditing={onSubmitEditing}
                        secureTextEntry={security}
                        editable={editable === undefined ? true : editable}
                        {...props}
                    />

                    {secureTextEntry && (
                        <TouchableOpacity style={styles.touch} onPress={() => setSecurity(!security)}>
                            {security ? (
                                <Eye width={20} height={20} color={"#B5B5B5"} />
                            ) : (
                                <EyeSlash width={20} height={20} color={"#B5B5B5"} />
                            )}
                        </TouchableOpacity>
                    )}
                </View>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        borderWidth: 1,
        borderRadius: 5,
        marginTop: Sizes.marginM,
    },
    input: {
        height: 45,
        width: "100%",
        backgroundColor: "#fff",
        paddingTop: 0,
        paddingBottom: 0,
        color: "#2B2B2B",
        borderRadius: 5,
        fontSize: Sizes.body3,
        paddingLeft: Sizes.marginS
    },
    touch: {
        position: 'absolute',
        right: 15,
        top: 15
    },
    icon: {
        position: "absolute",
        top: 13,
        left: 10
    }
})

export default Input