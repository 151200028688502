export function dateToHoursMinutes(
  d: string | Date | undefined,
  locales?: Intl.LocalesArgument,
  options?: Intl.DateTimeFormatOptions
): string {
  if (d === undefined) return "";

  const date = typeof d === "string" ? new Date(d) : d;
  return date.toLocaleTimeString(locales, options);
}

export function dateWithHoursLocale(): string {
  // Returns a date in the format dd/mm/yyyy hh:mm:ss
  const date = new Date();
  const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
    date.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${date.getFullYear()} ${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}:${date
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
  return formattedDate;
}
