import React from "react";
import { TouchableOpacityProps } from "react-native";
import { Text } from "native-base";
import { Container } from "./styles";
import { CategoryCardState } from "../../types";
import { Theme } from "../../styles";

type Props = TouchableOpacityProps & CategoryCardState;

export default function CategoryCard({
  name,
  volume,
  ...rest
}: Props): JSX.Element {
  return (
    <React.Fragment>
      <Container {...rest}>
        <Text fontSize={Theme.sizes.fontSizeL} color={Theme.colors.text_dark} maxWidth="70%">
          {name}
        </Text>
        <Text fontSize={Theme.sizes.fontSizeL} color={Theme.colors.text_dark}>
          {volume}
        </Text>
      </Container>
    </React.Fragment>
  );
}
