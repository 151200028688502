import React from 'react'
import Svg, { Path } from 'react-native-svg';
const ArrowBack = (props: {width: number, height: number, color: string}) => {

  return (
    <Svg width={props.width} height={props.height} viewBox="0 0 240 240" fill="none" >
      <Path d="M107 67.25C109 69.25 109.96 71.6667 109.88 74.5C109.793 77.3333 108.75 79.75 106.75 81.75L78.5 110L190 110C192.833 110 195.21 110.96 197.13 112.88C199.043 114.793 200 117.167 200 120C200 122.833 199.043 125.21 197.13 127.13C195.21 129.043 192.833 130 190 130H78.5L107 158.5C109 160.5 110 162.877 110 165.63C110 168.377 109 170.75 107 172.75C105 174.75 102.623 175.75 99.87 175.75C97.1233 175.75 94.75 174.75 92.75 172.75L47 127C46 126 45.29 124.917 44.87 123.75C44.4567 122.583 44.25 121.333 44.25 120C44.25 118.667 44.4567 117.417 44.87 116.25C45.29 115.083 46 114 47 113L93 67C94.8333 65.1667 97.1233 64.25 99.87 64.25C102.623 64.25 105 65.25 107 67.25V67.25Z" fill={props.color}/>
    </Svg>
  )
}

export default ArrowBack; 