import { IconButton } from "react-native-paper";
import styled from "styled-components/native";
import { Theme } from "../../styles";

export const Container = styled.View`
  bottom: 16px;
  right: 16px;
  align-self: flex-end;
  position: absolute;
`;

export const ButtonContainer = styled(IconButton)`
  min-width: 80px;
  max-width: 80px;
  max-height: 80px;
  min-height: 80px;
  border-radius: 40px;
  background-color: ${Theme.colors.primary};
`;
