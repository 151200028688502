import React from "react";
import { Center, Text } from "native-base";
import { Theme } from "../styles";

interface Props {
  message: string;
}

export function ListEmpty({ message }: Props): JSX.Element {
  return (
    <Center flexGrow={1}>
      <Text fontSize={Theme.sizes.fontSizeL} color={Theme.colors.text_dark}>
        {message}
      </Text>
    </Center>
  );
}
