import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import { openBrowserAsync } from "expo-web-browser";

import Help from "../svg/help";

interface VersionProps {
  color: string;
  helpText: string;
  url: string;
}

const VersionSideMenu = ({ color, helpText, url }: VersionProps) => {

    return (
        <TouchableOpacity
        onPress={() => openBrowserAsync(url)}
        style={styles.item}
      >
        <View style={styles.iconContainer}>
          <Help width={20} height={20} color={color} />
        </View>

        <View style={styles.item}>
          <Text style={styles.label}>
            {helpText}
          </Text>
        </View>
      </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  item: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    marginVertical: 4,
  },
  iconContainer: {
    marginLeft: 16,
    width: 24,
    alignItems: "center",
  },
  label: {
    margin: 4,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, .87)",
  },
});

export default VersionSideMenu;
