import React, { useRef } from "react";
import {
  FormControl,
  IconButton,
  Icon,
  Input,
  Text,
  Row,
  WarningOutlineIcon,
} from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { MeasurementCardState } from "../../types";
import { Theme } from "../../styles";
import i18nDictionary from "../../i18n";
import { Platform } from "react-native";
import { TextInput } from "react-native-gesture-handler";

type Props = MeasurementCardState & {
  onVolumeBlur: (volume: number) => void;
  onPress: () => void;
  isServiceCompleted: boolean;
};

function parseVolume(input: string): number {
  return parseFloat(input.replace(",", ".").trim());
}

function validateVolume(input: string): string {
  if (input.trim().length === 0) {
    return i18nDictionary.popups.errors.volumeCanNotBeEmpty();
  }

  const allowedCharsRegex = /^[0-9,.]+$/;
  if (!allowedCharsRegex.test(input)) {
    // TODO Tell users which chars are invalid in the error message
    return i18nDictionary.popups.errors.volumeHasInvalidChars();
  }

  const volumeRegex = /^[0-9]+([,.][0-9]+)?$/;
  if (!volumeRegex.test(input)) {
    return i18nDictionary.popups.errors.volumeInWrongFormat();
  }

  const value = parseVolume(input);
  if (isNaN(value)) {
    return i18nDictionary.popups.errors.volumeInvalid();
  }

  if (value < 0 || value > 100) {
    return i18nDictionary.popups.errors.volumeOutOfBounds();
  }

  // indicates validation passed
  return "";
}

export default function MeasurementCard({
  time,
  volume,
  onVolumeBlur: handlePatchVolume,
  onPress: handleOnPress,
  isServiceCompleted,
}: Props): JSX.Element {
  const [inputVolume, setVolume] = React.useState(volume);
  const [isInvalid, setIsInvalid] = React.useState(false);
  const [invalidVolumeMessage, setInvalidVolumeMessage] = React.useState("");
  const inputRef = useRef<TextInput>(null);

  const timeTextMarginRight = isServiceCompleted ? 4 : 0;

  const handleVolumeChange = (volume: string): void => {
    const errorMessage = validateVolume(volume);
    setInvalidVolumeMessage(errorMessage);
    setIsInvalid(errorMessage.length !== 0);
    if (errorMessage.length === 0) {
      handlePatchVolume(parseVolume(volume));
    }
  };

  return (
    <Row
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      marginY={1}
      backgroundColor={Theme.colors.backgroundLight}
      paddingY={2}
      paddingLeft={2}
      borderRadius={4}
    >
      <FormControl isInvalid={isInvalid} width="100%" flexShrink={1}>
        <Input
          value={inputVolume}
          ref={inputRef}
          color={Theme.colors.text_dark}
          selectTextOnFocus
          keyboardType="decimal-pad"
          onBlur={(e) => {
            if (Platform.OS === "web") handleVolumeChange(e.nativeEvent.text);
          }}
          onChangeText={setVolume}
          variant="outline"
          textAlign="left"
          width={20}
          fontSize={Theme.sizes.fontSizeL}
          isDisabled={isServiceCompleted}
          _disabled={{
            backgroundColor: Theme.colors.backgroundLight,
            borderColor: Theme.colors.backgroundLight,
            opacity: 1,
          }}
        />
        <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>
          {invalidVolumeMessage}
        </FormControl.ErrorMessage>
      </FormControl>
      <Text
        fontSize={Theme.sizes.fontSizeL}
        color={Theme.colors.text_dark}
        marginRight={timeTextMarginRight}
      >
        {time}
      </Text>

      {/* Only display delete button is service is not completed. */}
      {!isServiceCompleted && (
        <IconButton
          icon={
            <Icon as={MaterialIcons} name="clear" size="md" color="black" />
          }
          onPress={handleOnPress}
        />
      )}
    </Row>
  );
}
