import { theme } from "native-base";
import React, { useState } from "react";
import {
  ActivityIndicator,
  StyleSheet,
  Keyboard,
  TouchableOpacity,
  View,
} from "react-native";
import Text from "../../../shared/atoms/Text";
import { Sizes } from "../../../shared/constants/sizes";
import Input from "../../../shared/molecules/Input";
import i18nDictionary from "../../i18n";
import { Theme } from "../../styles";

interface FirstAccessProps {
  submitNewPassword: (password: string, newPassword: string) => void;
  loadingButton: boolean;
  errorMsg: string;
}

const FirstAccess = ({
  submitNewPassword,
  loadingButton,
  errorMsg,
}: FirstAccessProps) => {
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");

  return (
    <View style={styles.container}>
      <Text
        style={{
          fontSize: 48,
          color: Theme.colors.title,
          fontWeight: "700",
          marginVertical: 20,
        }}
      >
        goclever
      </Text>

      <Text
        style={{
          fontSize: Sizes.h1,
          fontWeight: "700",
          color: Theme.colors.title,
          marginBottom: 15,
        }}
      >
        {i18nDictionary.cognito.FirstAccess()}
      </Text>

      <Text
        style={{
          fontSize: Sizes.body2,
          color: Theme.colors.title,
          marginBottom: 15,
        }}
      >
        {/* {i18nDictionary.cognito.FirstAccessMessage1()} */}
      </Text>
      <Text
        style={{
          fontSize: Sizes.body2,
          color: Theme.colors.title,
          marginBottom: 15,
        }}
      >
        {/* {i18nDictionary.cognito.FirstAccessMessage2()} */}
      </Text>

      <View style={{ width: "100%" }}>
        <Input
          onChange={setPassword}
          value={password}
          error={errorMsg !== ""}
          label={i18nDictionary.cognito.NewPassword()}
          secureTextEntry={true}
        />
        <Input
          onChange={setNewPassword}
          value={newPassword}
          error={errorMsg !== ""}
          label={i18nDictionary.cognito.ConfirmPassword()}
          secureTextEntry={true}
          onSubmitEditing={() => {
            Keyboard.dismiss;
            submitNewPassword(password, newPassword);
          }}
        />
      </View>

      {errorMsg !== "" && (
        <Text
          style={{
            fontSize: Sizes.body5,
            color: Theme.colors.error_light,
            marginBottom: Sizes.marginM,
          }}
        >
          {errorMsg}
        </Text>
      )}

      <View style={{ marginVertical: 10 }}>
        <TouchableOpacity
          onPress={() => submitNewPassword(password, newPassword)}
          style={styles.loginButton}
        >
          {loadingButton ? (
            <ActivityIndicator color={Theme.colors.primary} size={25} />
          ) : (
            <Text
              style={{
                fontSize: Sizes.h3,
                fontWeight: "700",
                color: Theme.colors.primary,
              }}
            >
              {i18nDictionary.cognito.Submit()}
            </Text>
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: Theme.sizes.paddingM,
  },
  input: {
    borderColor: "gray",
    backgroundColor: "#FFF",
    width: "100%",
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  loginButton: {
    borderRadius: Theme.sizes.borderRadiusM,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Theme.colors.title,
    padding: Theme.sizes.paddingM,
    margin: 10,
  },
});

export default FirstAccess;
