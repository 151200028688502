import styled from "styled-components/native"
import { Theme } from "../styles";


const GoText = styled.Text.attrs((props) => ({ ...props }))`
  /* default black */
  color: ${(props) => props.color || Theme.colors.text};
  /* default 16px */
  font-size: ${(props) => props.size || Theme.sizes.fontSizeS}px;
  /* default left */
  text-align: ${(props) => props.align || "left"};
  /* default 0 */
  letter-spacing: ${(props) => props.spacing || "0"}px;

  text-decoration: ${(props) => props.sub ? "underline" : "none"};
  text-decoration-color: ${(props) => props.sub ? props.color : props.color};

  ${({
  primaryColor,
  secondaryColor,
  titleColor,
  blackColor,
  titleSecondaryColor,
  errorColor,
  warningColor,
}) => {
    switch (true) {
      case titleColor: {
        return { color: Theme.colors.primary };
      }
      case titleSecondaryColor: {
        return { color: Theme.colors.text_dark };
      }
      case primaryColor: {
        return { color: Theme.colors.title };
      }
      case secondaryColor: {
        return { color: Theme.colors.secondary };
      }
      case blackColor: {
        return { color: "#000" };
      }
      case errorColor: {
        return { color: Theme.colors.error };
      }
      case warningColor: {
        return { color: Theme.colors.warning };
      }
    }
  }}

  ${({ regular, bold, semi, extra, black, medium }) => {
    switch (true) {
      case regular: {
        return { fontWeight: 400 };
      }
      case bold: {
        return { fontWeight: 700 };
      }
      case semi: {
        return { fontWeight: 600 };
      }
      case extra: {
        return { fontWeight: 800 };
      }
      case black: {
        return { fontWeight: 900 };
      }
      case medium: {
        return { fontWeight: 500 };
      }
    }
  }}


${({
    mt1,
    mt2,
    mt3,
    mt4,
    mb1,
    mb2,
    mb3,
    mb4,
    ml1,
    ml2,
    ml3,
    ml4,
    mr1,
    mr2,
    mr3,
    mr4,
  }) => {
    switch (true) {
      case mt1: {
        return { marginTop: 5 };
      }
      case mt2: {
        return { marginTop: 10 };
      }
      case mt3: {
        return { marginTop: 15 };
      }

      case mt4: {
        return { marginTop: 20 };
      }

      case mb1: {
        return { marginBottom: 5 };
      }

      case mb2: {
        return { marginBottom: 10 };
      }

      case mb3: {
        return { marginBottom: 15 };
      }

      case mb4: {
        return { marginBottom: 20 };
      }

      case ml1: {
        return { marginLeft: 5 };
      }

      case ml2: {
        return { marginLeft: 10 };
      }

      case ml3: {
        return { marginLeft: 15 };
      }

      case ml4: {
        return { marginLeft: 20 };
      }

      case mr1: {
        return { marginRight: 5 };
      }

      case mr2: {
        return { marginRight: 10 };
      }

      case mr3: {
        return { marginRight: 15 };
      }

      case mr4: {
        return { marginRight: 20 };
      }
    }
  }}


  ${({ h1, h2, h3, h4, h5, h6 }) => {
    switch (true) {
      case h1: {
        return { fontSize: `${Theme.sizes.fontSizeXL}px`, fontWeight: 700 };
      }
      case h2: {
        return { fontSize: `${Theme.sizes.fontSizeL}px`, fontWeight: 700 };
      }
      case h3: {
        return { fontSize: `${Theme.sizes.fontSizeM}px`, fontWeight: 700 };
      }
      case h4: {
        return { fontSize: `${Theme.sizes.fontSizeS}px`, fontWeight: 700 };
      }
      case h5: {
        return { fontSize: `${Theme.sizes.fontSizeXS}px`, fontWeight: 700 };
      }
    }
  }}


  ${({ body1, body2, body3, body4, body5, body6 }) => {
    switch (true) {
      case body1: {
        return { fontSize: `${Theme.sizes.fontSizeXL}px` };
      }
      case body2: {
        return { fontSize: `${Theme.sizes.fontSizeL}px` };
      }
      case body3: {
        return { fontSize: `${Theme.sizes.fontSizeM}px` };
      }
      case body4: {
        return { fontSize: `${Theme.sizes.fontSizeS}px` };
      }
      case body5: {
        return { fontSize: `${Theme.sizes.fontSizeXS}px` };
      }
    }
  }}

`;

export default GoText;
