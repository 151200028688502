import { extendTheme } from "native-base";

export const Theme = {
  colors: {
    primary: "#06f",
    primary_lighter: "#7EC8E3",
    primary_light: "#4D94FF",
    primary_darker: "#050A30",
    primary_dark: "#003380",

    secondary: "#767676",
    secondary_light: "#408cff",

    success: "#12A454",
    success_ligh: "#75efac",

    error: "#E83F5b",
    error_light: "#ee768a",

    warning: "#F5A000",

    border: "#B5B5B5",

    title: "#fff",
    text: "#e7e3dee4",
    text_dark: "#2B2B2B",
    text_black: "#000",
    background: "#F1F1F1",
    backgroundLight: "#F7F7F7",
  },
  sizes: {
    fontSizeXXL: 24,
    fontSizeXL: 20,
    fontSizeL: 16,
    fontSizeM: 14,
    fontSizeS: 12,
    fontSizeXS: 10,

    paddingL: 20,
    paddingM: 15,
    paddingS: 10,

    marginL: 20,
    marginM: 15,
    marginS: 10,
    marginXS: 5,

    borderRadiusXL: 150,
    borderRadiusL: 50,
    borderRadiusM: 25,
    borderRadiusS: 5,

    heightXL: 150,
    heightL: 120,
    heightM: 60,
    heightS: 45,
  },
};

export const NativeBaseTheme = extendTheme({
  colors: {
    primary: {
      50: "#EFF5FF",
      100: "#9fc5ff",
      200: "#77aeff",
      300: "#5096ff",
      400: "#287eff",
      500: "#0066ff",
      600: "#075ee1",
      700: "#0c56c3",
      800: "#114da8",
      900: "#13448e",
    },
  },
  components: {
    Button: {
      defaultProps: {
        size: "lg",
      },
    },
  },
});
