import { Dictionary } from "../index";

const en: Dictionary = {
  screens: {
    NotFound: {
      message: "Page not found",
    },
    Measures: {
      scale: "Port-Call",
      category: "Category",
      container: "Container",
      device: "Device",
      measurementType: "Measurement type",
      tare: "Tare",
      submit: "Submit",
      dashboard: "Dashboard",
      newMeasurement: "New measurement",
      volume: "Volume",
      dimensions: "Dimensions",
      time: "Time",
      download3DView: "Download 3D view",
      errorMessageSubmit: "Error at submitting required measure, try again!",
      errorMessageUnknownDevice: "Unknown device",
      errorMessageUnknownContainer: "Unknown container",
      errorMessageUnboundTimeout: "Unbound timeout",
      errorMessageUnauthorizedRequest: "Not Authorized",
      errorMessageDeviceNotReady:
        "Server isn't ready to start a new volume measurement",
      errorMessageVolumeComputation: "Volume computation error",
      errorMessageUnexpected: "A problem in the server",
      errorMessageUnknownUsecase: "Unknown use case",
      errorMessageUnboundDelay: "Unbound Delay",
      errorMessageInvalidContentType: "Invalid Content Format",
    },
    Home: {
      emptyServiceList: "Click on the '+' button to schedule a new service.",
      emptyCompletedServices: "There aren't any completed services.",
      scheduled: "Scheduled",
      completed: "Completed",
      version: "Version",
      helpPage: "https://goclever.io/wasteapp-en/",
      help: "Help",
    },
    CreateService: {
      header: "Waste Discharge Register",
      orderNumber: "Order Number",
      register: "Register",
      cancel: "Cancel",
    },
    ServiceCategories: {
      category: "Category",
      measured: "Measured",
      completeService: "Complete service",
      emptyCategoriesList: "No categories available.",
    },
    ServiceMeasurements: {
      declared: "Declared",
      measured: "Measured",
      measurement: "Measurement",
      volume: "Volume",
      newMeasurement: "New measurement",
      emptyMeasurementsList: "No measurements done.",
      container: "Container",
      device: "Device",
    },
  },
  cognito: {
    SignIn: "Sign In",
    SignOut: "Sign Out",
    Username: "Username",
    Password: "Password",
    Email: "Email",
    Confirm: "Confirm",
    Cancel: "Cancel",
    Submit: "Submit",
    Skip: "Skip",
    Verify: "Verify",
    Code: "Code",
    Anonymous: "Access anonymously",
    IncorrectLogin: "Incorrect username or password",
    ChangePassword: "Change password",
    OldPassword: "Last password",
    NewPassword: "New password",
    ConfirmPassword: "Repeat password",
    ForgotPassword: "Forgot the password?",
    EmailConfirmCode: "Change password with code",
    ConfirmationCode: "Confirmation Code",
    FirstAccess: "First Access",
    SuccessMessageChangePassword: "Password changed with success",
    SuccessMessageSubmit: "Measure submitted with success!",
    SuccessMessageTara: "Tare submitted with success!",
    ErrorMessageDifferentPasswords: "Password confirmation doesn't match",
    ErrorEmptyFieldsMessage:
      "Incomplete fields, please fulfill all fields and try again!",
    ErrorGeneralMessage: "There was an error, try again!",
    NotAuthorizedException: "Password incorrect, try again!",
    UserNotFoundException: "Username/client id combination not found.",
    CodeMismatchException:
      "Invalid verification code provided, please try again.",
    InvalidPasswordException:
      "Password does not conform to policy: Password not long enough",
    InvalidParameterException: "Empty username and/or password field",
    PasswordResetRequiredException: "Password reset required for the user",
  },
  popups: {
    errors: {
      computationOngoing: "Computation is still ongoing.",
      computationTimeout: "It will continue on background!",
      downloadErrorTitle: "Error during download",
      downloadErrorText: "Please, try again later.",
      fetchErrorTitle: "Error",
      fetchErrorText: "Services couldn't be retrieved.",
      registrationErrorTitle: "Registration error",
      registrationErrorText: "Service could not be registered.",
      updateErrorTitle: "Update error",
      updateErrorText: "Service could not be completed.",
      measurementErrorTitle: "Measurement error",
      measurementDeleteErrorTitle: "Error",
      measurementDeleteErrorText: "Measurement could not be deleted.",
      measurementPatchErrorTitle: "Error",
      measurementPatchErrorText:
        "Measurement could not be updated. Try again, please.",
      orderNumberCanNotBeEmpty: "Order number cannot be empty",
      orderNumberTooLong: "Order number is too long",
      volumeCanNotBeEmpty: "Volume cannot be empty",
      volumeHasInvalidChars: "Volume has invalid characters.",
      volumeInWrongFormat: "Volume must a number of format 0.00",
      volumeInvalid: "Invalid volume",
      volumeOutOfBounds: "Volume must be between 0 and 100",
    },
    success: {
      volume: "Volume",
      deletedVolumeTitle: "Volume deleted",
    },
  },
};

export default en;
