import React from "react";
import { Input as NativeInput, IInputProps } from "native-base";
import { Theme } from "../styles";

export function Input({ ...rest }: IInputProps): JSX.Element {
  return (
    <NativeInput
      backgroundColor={Theme.colors.backgroundLight}
      minHeight={14}
      padding={2}
      borderWidth={0}
      fontSize={"md"}
      color={Theme.colors.text_dark}
      {...rest}
    />
  );
}
