import registerRootComponent from "expo/build/launch/registerRootComponent";
import App from "./App";
import "./App.css";

// import { Amplify } from '@aws-amplify/core'
// import config from './aws-exports.js'

// Amplify.configure(config)

registerRootComponent(App);
