import styled from "styled-components/native";
import { Theme } from "../../styles";
import { TouchableOpacity } from "react-native";

export const Container = styled(TouchableOpacity)`
  width: 100%;
  height: 60px;
  background-color: ${Theme.colors.backgroundLight};
  border-radius: 6px;
  padding: 8px 12px;
  margin-bottom: 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.Text`
  font-size: ${Theme.sizes.fontSizeL}px;
  color: ${Theme.colors.text_dark};
  text-align-vertical: center;
`;
