import styled from "styled-components/native";
import { Theme } from '../../styles';

export const Container = styled.View`
  height: 65px;
  width: 100%;
  background-color: ${Theme.colors.primary};
  padding-left: 15px;
  padding-right: 15px;
`;

export const Content = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  height: 100%;
`;

export const ContentButton = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const ReturnButton = styled.View`
  margin-right: 10px;
  border-radius: 50px;
  height: 35px;
  width: 35px;
  align-items: center;
  justify-content: center;
`;

export const Return = styled.TouchableOpacity.attrs(() => ({}))`
  
`;

export const Logo = styled.Image.attrs(() => ({
  resizeMode: "contain",
}))`
  width: 175px;
  height: 175px;
  margin-left: 100px;  
`;
